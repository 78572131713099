import _ from 'lodash'
import { ActionTypes, MutationTypes, useStore } from '@/store'
import { PptState } from '@/types/pptState'

export default () => {
  const store = useStore()

  // 添加历史快照(历史记录)
  const addHistorySnapshot = _.debounce(function() {
    store.dispatch(ActionTypes.ADD_SNAPSHOT)
    // 修改ppt状态
    store.commit(MutationTypes.SET_PPT_STATE, PptState.MODIFY)
  }, 300, { trailing: true })

  // 重做
  const redo = _.throttle(function() {
    store.dispatch(ActionTypes.RE_DO)
  }, 100, { leading: true, trailing: false })

  // 撤销
  const undo = _.throttle(function() {
    store.dispatch(ActionTypes.UN_DO)
  }, 100, { leading: true, trailing: false })

  // 删除最后一张历史快照
  const deleteLastHistorySnapshot = _.debounce(function() {
    store.dispatch(ActionTypes.DELETE_LAST_SNAPSHOT)
    // 修改ppt状态
    store.commit(MutationTypes.SET_PPT_STATE, PptState.MODIFY)
  }, 100, { trailing: true })

  return {
    addHistorySnapshot,
    deleteLastHistorySnapshot,
    redo,
    undo,
  }
}
