import _ from 'lodash'
import { useStore } from '@/store'
import { computed, watch } from 'vue'
import { ElfElementTypes, PPTElfElement } from '@/types/slides'
let activeElements = {}

/**
 * 1. 编辑时
 * 	1. 无需关注，每次都下载
 * 2. 放映时
 *  2.1 页面是属于第一份曲谱，则需要初始化
 *  2.2 当前页面是本页面，并且本页面是曲谱，则需要初始化
 *  3.3 当前页面不是本页面，但是没有其他页面销毁
 */
export default (singleElementTypes: Array<ElfElementTypes> = []) => {
	const store = useStore()

	const currentSlide = computed(() => {
		return store.getters.currentSlide
	})

	const currentSlideElements = computed(() => currentSlide.value.elements)
	const slides = computed(() => {
		return store.state.slides
	})
	const allSlideElements = computed(() => {
		return _.flatMap(slides.value, (slide) => {
			return slide.elements.map((el: any) => ({ ...el, sildeId: slide.id }))
		})
	})

	const screening = computed(() => store.state.screening)
	const pptUrl = computed(() => store.state.pptUrl)

	// 编辑时 结束放映 退到编辑页面，清除状态
	watch(
		() => screening.value,
		(newVal) => {
			!newVal && (activeElements = {})
		},
		{
			immediate: true
		}
	)

	// 切换ppt 清除状态
	watch(
		() => pptUrl.value,
		() => {
			activeElements = {}
		},
		{ immediate: true }
	)

	const canRender = (elId: string, elType: ElfElementTypes | string) => {
		if (!screening.value) {
			return true
		}
		const isInclude = singleElementTypes.includes(elType as ElfElementTypes)

		if (!isInclude) {
			return true
		}

		const element = allSlideElements.value.find(
			(e) => e.id === elId
		) as PPTElfElement

		// fixme 因为采用了svg方案，速度起来，因此不预加载第一份
		// const activeElement = activeElements[element.subtype]

		// const elementIndex = allSlideElements.value
		// 	.filter((el: any) => el.subtype === element.subtype)
		// 	.findIndex((el) => el.id === element.id)

		//* 首次，并且当前页面是单实例类型的element的第一个
		// if (!activeElement && elementIndex === 0) {
		// 	activeElements[element.subtype] = elId
		// 	return true
		// }

		const currentSlideElementIds = currentSlideElements.value.map(
			(el: any) => el.id
		)
		//* 当前页面包含本element
		if (currentSlideElementIds.includes(elId)) {
			activeElements[element.subtype] = elId
			return true
		}

		//* 当前页面 撤退到后面的element
		const currentSlideElementSubtypes = currentSlideElements.value.map(
			(el: any) => el.subtype
		)
		if (
			activeElements[element.subtype] === elId &&
			!currentSlideElementSubtypes.includes(element.subtype)
		) {
			activeElements[element.subtype] = elId
			return true
		}

		return false
	}

	return {
		canRender
	}
}
