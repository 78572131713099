import { ref, toRaw, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import logger from '@evideo/logger'
import useUpLoadResource, { UpLoadReply } from './useUpLoadResource'
import useElectron, { MediaTranscodeProgress } from './useElectron'
import useShowMessage from './useShowMessage'

export type MediaTranscodeState = 'doing' | 'ready' | 'success' | 'fail'
export type MediaType = 'video' | 'audio' | 'image'
export type MediaTranscodeType = 'video' | 'audio' | 'image' | 'ignore'
export type MediaTranscodeMode = 'transcode' | 'upload'

export type NeedTranscodeType = 'format' | 'size' | 'error' | ''

export type MediaTranscodeListItem = {
	id: string // id
	transcodeId: number // 转码id
	transcodeOptions: MediaTranscodeOptions // 转码options
	transcodeType: MediaTranscodeType // 转码类型
	type: MediaType // 文件类型
	state: MediaTranscodeState // 当前转码上传状态
	name: string // 文件名称
	size: number // 文件原大小
	progress: number // 总进度
	transcodeProgress: number // 转码进度
	uploadProgress: number // 上传进度
	slideId?: string // 关联幻灯片id
	uploadCheckpoint?: {
		// 上传文件信息
		name: string
		uploadId: string
	}
	result?: {
		// 文件上传结果
		rendered: boolean
		uploadReply: UpLoadReply
	}
	filePath: string // 文件目录
	mode: MediaTranscodeMode // 模式： 转码 ｜ 上传
	file?: File | undefined // 浏览器上传
}

export type MediaTranscodeOptions = {
	toFormat: string
	width?: number
	height?: number
	bitrate?: string
	size?: string
}

export type MediaTranscodeTask = {
	name: string
	size: number
	type: string
	filePath: string
	mode: MediaTranscodeMode
	transcodeType: MediaTranscodeType
	file?: File
}

export const MEDIA_TRANSCODE_STATE_READY = 'ready'
export const MEDIA_TRANSCODE_STATE_DOING = 'doing'
export const MEDIA_TRANSCODE_STATE_SUCCESS = 'success'
export const MEDIA_TRANSCODE_STATE_FAIL = 'fail'

const mediaTranscodeList = ref<MediaTranscodeListItem[]>([])

export default () => {
	const fileFiltersMap = {
		Image: { name: 'Images', extensions: ['gif', 'jpeg', 'png', 'jpg'] },
		Video: {
			name: 'Movies',
			extensions: [
				'mp4',
				'mov',
				'3gp',
				'mkv',
				'vob',
				'm4v',
				'mts',
				'avi',
				'flv',
				'webm',
				'mpg',
				'rm',
				'wmv'
			]
		},
		Audio: { name: 'Audios', extensions: ['wav', 'm4a', 'flac', 'mp3'] },
		all: { name: 'All Files', extensions: ['*'] }
	}
	const {
		mediaTranscoding,
		removeTranscodeListener,
		killMediaTranscode
	} = useElectron()
	const { getResourceUrl, getImageResourceUrl, abort } = useUpLoadResource()
	const { showMeassage } = useShowMessage()

	/** 添加资源转码任务 */
	const addMediaTranscodeTask = (
		task: MediaTranscodeTask,
		options: MediaTranscodeOptions
	) => {
		const newTask = {
			...task,
			transcodeOptions: options,
			type: task?.type as MediaType,
			id: `media_transcode_` + uuidv4(),
			transcodeId: 0,
			progress: 0,
			transcodeProgress: 0,
			uploadProgress: 0,
			state: MEDIA_TRANSCODE_STATE_READY as MediaTranscodeState
		}
		mediaTranscodeList.value.push(newTask)
	}
	/** 资源转码 */
	const handleMediaTranscoding = (index: number) => {
		const item = mediaTranscodeList.value?.[index]
		if (!item) {
			return
		}
		return new Promise((resolve, reject) => {
			const options = {}
			if (item?.transcodeType === 'ignore') {
				options['type'] = 'ignore'
			} else {
				options['type'] = item?.type
				options[item?.type] = toRaw(item.transcodeOptions)
			}

			/** 转码成功回调 */
			const successMediaTranscode = (info: {
				transCodeId: number
				media: { data: ArrayBuffer; inputName: string; outputName?: string }
			}) => {
				logger.info(
					`媒体资源转码成功:type ${item.type},name ${item.name} transcodeId:${
						item?.transcodeId
					} options: ${JSON.stringify(item.transcodeOptions)}`
				)
				// 移除转码监听
				removeTranscodeListener(item.transcodeId)
				_.merge(item, {
					progress: 50,
					transcodeProgress: 100,
					mode: 'upload'
				})
				const fileName = info.media.inputName.split('.')
				let suffix = fileName.pop()
				if (info.media?.outputName) {
					suffix = info.media.outputName.split('.').pop()
				}
				const file = new File(
					[info.media.data],
					fileName.join('.') + '.' + suffix
				)
				// 上传
				uploading(file, index)
				resolve('')
			}
			/** 转码失败回调 */
			const failMediaTranscode = (e: any) => {
				const isExit = mediaTranscodeList.value.findIndex(
					(_item) => _item.id === item.id
				)
				if (isExit < 0) {
					return
				}
				logger.error(
					`媒体资源转码失败:type ${item.type},name ${item.name} transcodeId:${
						item?.transcodeId
					} options: ${JSON.stringify(item.transcodeOptions)}`
				)
				// 移除转码监听
				removeTranscodeListener(item.transcodeId)
				_.merge(item, {
					state: MEDIA_TRANSCODE_STATE_FAIL
				})
				logger.error('添加媒体转换失败：', e)
				showMeassage('媒体转换失败', 'error')
				reject(e)
			}
			/** 转码监听进度回调 */
			const transcodeListenerFunc = (res: MediaTranscodeProgress) => {
				const { percent } = res || {}
				const currentTask = mediaTranscodeList.value.find(
					(item) => item.state === MEDIA_TRANSCODE_STATE_DOING
				)
				if (currentTask) {
					currentTask.transcodeProgress = (percent / 100) * 50
					currentTask.progress = (percent / 100) * 50
				}
			}
			/** 开始转码后执行 */
			const afterBeginMediaTranscoding = (transcodeId: number) => {
				logger.info(
					`开始进行媒体资源转码: transcodeType ${item.transcodeType} type ${
						item.type
					},name ${
						item.name
					} transcodeId:${transcodeId} options: ${JSON.stringify(
						item.transcodeOptions
					)}`
				)
				_.merge(item, {
					state: MEDIA_TRANSCODE_STATE_DOING,
					transcodeId: transcodeId
				})
			}
			if (item.mode === 'transcode') {
				// 仅在软件上支持转码
				mediaTranscoding(
					item?.filePath,
					options,
					successMediaTranscode,
					failMediaTranscode,
					transcodeListenerFunc,
					afterBeginMediaTranscoding
				)
			} else {
				// 浏览器上直接上传
				item?.file && uploading(item.file, index, 100)
			}
		})
	}

	/** 上传资源 */
	const uploading = async (file: File, index: number, percent = 50) => {
		try {
			// const checkIsSpaceLimit = await canUploadByFileSize(file)
			// if (!checkIsSpaceLimit) {
			// 	const item = mediaTranscodeList.value[index]
			// 	_.merge(item, {
			// 		state: MEDIA_TRANSCODE_STATE_FAIL
			// 	})
			// 	showMeassage('上传失败，剩余空间不足', 'error')
			// 	return
			// }
			const progress = (p: number, _checkpoint: any) => {
				if (mediaTranscodeList.value?.[index]) {
					mediaTranscodeList.value[index].uploadProgress = p * percent
					mediaTranscodeList.value[index].progress = 100 - percent + p * percent
					mediaTranscodeList.value[index]['uploadCheckpoint'] = _checkpoint
				}
			}
			const uploadResource =
				mediaTranscodeList.value[index]?.type === 'image'
					? getImageResourceUrl
					: getResourceUrl
			const uploadRes: UpLoadReply = await uploadResource(file, progress)
			if (mediaTranscodeList.value?.[index]) {
				_.merge(mediaTranscodeList.value[index], {
					state: MEDIA_TRANSCODE_STATE_SUCCESS,
					result: {
						rendered: false,
						uploadReply: uploadRes
					},
					file: undefined,
					progress: 100,
					uploadProgress: 100
				})
			}
		} catch (e) {
			if ((e as any)?.status === 0) {
				// 中止任务上传
				// deleteMediaTranscodeTask(mediaTranscodeList.value[index].id)
			} else {
				const item = mediaTranscodeList.value[index]
				_.merge(item, {
					state: MEDIA_TRANSCODE_STATE_FAIL
				})
				logger.error(
					`媒体资上传失败:type ${item.type},name ${item.name} transcodeId:${
						item?.transcodeId
					} ${e} ${(e as any)?.message} `
				)
				showMeassage('媒体上传失败', 'error')
				// logger.error(e)
				// isError.value = true
			}
		}
	}
	/**
	 * 取消文件转码/上传
	 * 仅在准备、进行中中可删除 */
	const handleMediaCancel = (id: string) => {
		return new Promise((resolve, reject) => {
			const currentTask = mediaTranscodeList.value.find(
				(item) => item.id === id
			)
			if (currentTask) {
				if (currentTask.state === MEDIA_TRANSCODE_STATE_SUCCESS) {
					logger.warn(
						`资源已经转码上传成功，不能删除: transcodeType ${currentTask.transcodeType} type ${currentTask.type},name ${currentTask.name} transcodeId:${currentTask.transcodeId}}`
					)
					resolve('')
					return
				}
				if (currentTask.state === MEDIA_TRANSCODE_STATE_READY) {
					logger.info(
						`取消资源转码上传: transcodeType ${currentTask.transcodeType} type ${currentTask.type},name ${currentTask.name} transcodeId:${currentTask.transcodeId}}`
					)
					deleteMediaTranscodeTask(id)
					resolve('')
					return
				}
				switch (currentTask.mode) {
					case 'transcode': {
						killMediaTranscode(currentTask.transcodeId)
						logger.info(
							`取消资源转码: transcodeType ${currentTask.transcodeType} type ${currentTask.type},name ${currentTask.name} transcodeId:${currentTask.transcodeId}}`
						)
						deleteMediaTranscodeTask(id)
						resolve('')
						break
					}
					case 'upload': {
						if (currentTask.uploadCheckpoint) {
							abort(
								currentTask.uploadCheckpoint.name,
								currentTask.uploadCheckpoint.uploadId
							)
								.then(() => {
									logger.info(
										`取消资源上传: transcodeType ${currentTask.transcodeType} type ${currentTask.type},name ${currentTask.name} transcodeId:${currentTask.transcodeId}}`
									)
									deleteMediaTranscodeTask(id)
									resolve('')
								})
								.catch((e) => {
									reject(e)
								})
						} else {
							resolve('')
						}
						break
					}
					default:
						resolve('')
						break
				}
			} else {
				resolve('')
			}
		})
	}
	/** 删除列表项 */
	const deleteMediaTranscodeTask = (id: string) => {
		const _tempList = mediaTranscodeList.value.filter((item) => item.id !== id)
		mediaTranscodeList.value.length = 0
		mediaTranscodeList.value.push(..._tempList)
	}
	/** 判断空间是否能容纳文件上传 */
	// const canUploadByFileSize = (file: File) => {
	// 	return new Promise((resolve, reject) => {
	// 		const size = file.size
	// 		fetchApi({
	// 			url: `https://${process.env.VUE_APP_RESOURCE_CENTER_HOST}/teacher/profile/space`,
	// 			method: 'get',
	// 			timeout: 3000
	// 		})
	// 			.then((spaceData) => {
	// 				const remainingSpace =
	// 					spaceData.data.data.space_limit - spaceData.data.data.total_size
	// 				resolve(size <= remainingSpace ? true : false)
	// 			})
	// 			.catch(reject)
	// 	})
	// }

	watch(
		() => mediaTranscodeList.value,
		_.debounce(() => {
			const isExitDoingTask = mediaTranscodeList.value.find(
				(item) => item.state === MEDIA_TRANSCODE_STATE_DOING
			)
			if (!isExitDoingTask) {
				const nextReadyTaskIndex = mediaTranscodeList.value.findIndex(
					(item) => item.state === MEDIA_TRANSCODE_STATE_READY
				)
				if (nextReadyTaskIndex >= 0) {
					handleMediaTranscoding(nextReadyTaskIndex)
					_.merge(mediaTranscodeList.value[nextReadyTaskIndex], {
						state: MEDIA_TRANSCODE_STATE_DOING
					})
				}
			}
		}, 100),
		{ deep: true }
	)
	/** 处理图片转码options */
	const handleImageTranscodeOptions = (
		imageInfo: any,
		general: any,
		format = ''
	) => {
		const { Width, Height, StreamSize, Rotation } = imageInfo || {}
		const { CodecID, FileSize } = general || {}

		const rotate = Rotation && +Rotation ? +Rotation : 0
		const width = (rotate / 90) % 2 === 0 ? Width : Height
		const height = (rotate / 90) % 2 === 0 ? Height : Width

		const result = {
			needTranscode: false,
			needTranscodeType: '',
			canUseMediaTranscode: false,
			options: {
				toFormat: ''
			},
			extra: {
				size: StreamSize || (FileSize && parseInt(FileSize))
			}
		}
		if (format?.toUpperCase() === 'GIF') {
			_.merge(result, {
				needTranscodeType: 'format'
			})
			return result
		}
		if (CodecID === 'heic') {
			logger.error(`当前文件为heic图片，暂不支持解码、不支持上传`)
			_.merge(result, {
				needTranscodeType: 'error'
			})
			return result
		}
		const imageOptions: MediaTranscodeOptions = {
			toFormat: 'png'
		}
		const size = (StreamSize || (FileSize && parseInt(FileSize))) / 1024 / 1024

		if (Math.max(width, height) > 1920) {
			const rate = width / height
			if (width > height) {
				imageOptions['width'] = 1920
				imageOptions['height'] = 1920 / rate
			} else {
				imageOptions['width'] = 1080 * rate
				imageOptions['height'] = 1080
			}
		} else {
			imageOptions['width'] = width
			imageOptions['height'] = height
		}
		// 资源格式、宽高检查
		const passFormatCheck =
			['JPG', 'PNG', 'JPEG'].includes(format.toUpperCase()) &&
			Math.max(width, height) <= 1920
		// 资源大小检查
		const passSizeCheck = size < 1

		const _needTranscode = [width, height].includes(undefined)
			? false
			: passFormatCheck && passSizeCheck
			? false
			: true

		_.merge(result, {
			canUseMediaTranscode: true,
			needTranscode: _needTranscode,
			options: imageOptions,
			needTranscodeType: _needTranscode
				? !passFormatCheck
					? 'format'
					: 'size'
				: ''
		})
		logger.info(
			`图片资源信息: CodecID:${CodecID}; width:${Width}; height:${Height}; Rotation:${Rotation}; StreamSize:${StreamSize} result:${JSON.stringify(
				result
			)}}`
		)
		return result
	}
	/** 处理音频转码options */
	const handleAudioTranscodeOptions = (
		audioInfo: any,
		general: any,
		format = ''
	) => {
		const { Format } = general || {}
		const { BitRate, StreamSize } = audioInfo || {}
		const result = {
			needTranscode: false,
			canUseMediaTranscode: false,
			options: {
				toFormat: 'mp3',
				bitrate: BitRate > 128 * 1024 ? 128 * 1024 : BitRate
			},
			extra: {
				size: StreamSize
			}
		}
		const size = StreamSize / 1024 / 1024

		// 资源格式、bitrate检查
		const passFormatCheck =
			Format === 'MPEG Audio' &&
			format.toLocaleLowerCase() === 'mp3' &&
			BitRate <= 128 * 1024
		// 资源大小检查
		const passSizeCheck = size < 5

		const _needTranscode = passFormatCheck && passSizeCheck ? false : true
		_.merge(result, {
			canUseMediaTranscode: true,
			needTranscode: _needTranscode,
			needTranscodeType: _needTranscode
				? !passFormatCheck
					? 'format'
					: 'size'
				: ''
		})
		logger.info(
			`音频资源信息: Format:${Format}; Bitrate ${BitRate}; StreamSize:${StreamSize} result:${JSON.stringify(
				result
			)}}`
		)
		return result
	}

	/** 处理视频转码options */
	const handleVideoTranscodeOptions = (
		videoInfo: any,
		general: any,
		format = ''
	) => {
		const { Format, DataSize } = general || {}
		const {
			BitRate,
			InternetMediaType,
			Width,
			Height,
			Rotation,
			StreamSize,
			FrameRate
		} = videoInfo || {}
		const size = (StreamSize || DataSize) / 1024 / 1024
		const rotate = Rotation && +Rotation ? +Rotation : 0
		const width = (rotate / 90) % 2 === 0 ? Width : Height
		const height = (rotate / 90) % 2 === 0 ? Height : Width
		const result = {
			needTranscode: false,
			canUseMediaTranscode: false,
			options: {
				toFormat: 'mp4',
				bitrate: BitRate > 2000 * 1024 ? 2000 * 1024 : BitRate,
				maxBitrate: 2000 * 1024,
				rate: FrameRate
			},
			extra: {
				size: StreamSize || DataSize
			}
		}
		if (Math.max(width, height) > 1920) {
			const rate = width / height
			if (width > height) {
				result.options['width'] = 1920
				result.options['height'] = Math.round(1920 / rate)
			} else {
				result.options['height'] = 1920
				result.options['width'] = Math.round(1920 * rate)
			}
		} else {
			result.options['width'] = width
			result.options['height'] = height
		}
		result.options[
			'size'
		] = `${result.options['width']}x${result.options['height']}`

		// 资源格式类、尺寸检查
		const passFormatCheck =
			InternetMediaType?.includes('H264') && // H264编码
			Format === 'MPEG-4' &&
			format.toLocaleLowerCase() === 'mp4' && // mp4格式
			FrameRate <= 30 && // rate < 30
			Math.max(width, height) <= 1920 && // 宽高在1920以内
			BitRate <= 2000 * 1024 // bitrate < 2000k

		// 资源大小检查
		const passSizeCheck = size < 20 // 20M以内

		const _needTranscode = passFormatCheck && passSizeCheck ? false : true
		_.merge(result, {
			canUseMediaTranscode: true,
			needTranscode: _needTranscode,
			needTranscodeType: _needTranscode
				? !passFormatCheck
					? 'format'
					: 'size'
				: ''
		})
		logger.info(
			`视频资源信息: Format:${Format}; InternetMediaType:${InternetMediaType}; Bitrate ${BitRate}; rate:${FrameRate}; width:${Width}; height:${Height}; Rotation:${Rotation}; StreamSize:${StreamSize} result:${JSON.stringify(
				result
			)}}`
		)
		return result
	}

	return {
		mediaTranscodeList,
		fileFiltersMap,
		addMediaTranscodeTask,
		handleImageTranscodeOptions,
		handleAudioTranscodeOptions,
		handleVideoTranscodeOptions,
		handleMediaTranscoding,
		handleMediaCancel
	}
}
