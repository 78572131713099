import { computed, ref, watch } from 'vue'
import useTeachingTabPage from './useTeachingTabPage'
import { useStore } from '@/store'
import { ElementTypes, ElfElementTypes, Slide } from '@/types/slides'

export interface IPagePenSize {
	canvasWidth: number | string
	canvasHeight: number | string
	containerWidth: number | string
	containerHeight: number | string
}

export interface IPagePen {
	id: string
	pagePenSize: IPagePenSize
}

/**
 * 1.画笔模式下，滚动画布通知曲谱滚动
 * 2.鼠标模式下，滚动曲谱通知画布滚动
 */

let newScrollTop = 0
const slideScale = ref(1)
const isPenOpened = ref(false)
const isPenDraw = ref(true) // 默认为画笔模式
const isPptSingPlay = ref(false)
const { sendToHost, receiveHostMessage } = useTeachingTabPage()
/** 打开ppt时，初始化画笔状态 */
const initTeachingPen = (penOpened: boolean, penDrawMode: number) => {
	isPenOpened.value = penOpened
	if (penDrawMode === 1 || !penDrawMode) {
		isPenDraw.value = false
	} else {
		isPenDraw.value = true
	}
	receiveHostMessage('pen-opened', (e, penOpened) => {
		isPenOpened.value = penOpened
	})
	receiveHostMessage('pen-change-drawMode', (e, { type } = penDrawMode) => {
		if (type === 1) {
			isPenDraw.value = false
		} else {
			isPenDraw.value = true
		}
	})
	receiveHostMessage('pen-container-scroll', (e, scrollTop) => {
		const dom = document.querySelector<HTMLElement>('#paper')
		// 当前为画笔（橡皮擦等）模式；且不在播放、暂停状态时，才可监听曲谱滚动，从而滚动画布
		if (dom && isPenOpened.value && isPenDraw.value && !isPptSingPlay.value) {
			dom.scrollTop = scrollTop / slideScale.value
		}
	})
}
export { initTeachingPen }
export default () => {
	const store = useStore()

	const currentSlide = computed<Slide>(() => store.getters.currentSlide)
	const isCurrentSingPlaySlide = computed(
		() =>
			currentSlide.value.elements.findIndex(
				(el) =>
					el.type === ElementTypes.ELF &&
					el.subtype === ElfElementTypes.SING_PLAY
			) > -1
	)

	const setSlideScale = (_slideScale = 1) => {
		slideScale.value = _slideScale
	}

	const sendPenStage = (container: HTMLElement = document.body) => {
		if (!isPenOpened.value) {
			return
		}
		let pagePenSize: IPagePenSize = {
			canvasWidth: container.clientWidth,
			canvasHeight: container.clientHeight - 80,
			containerWidth: container.clientWidth,
			containerHeight: container.clientHeight - 80
		}
		if (isCurrentSingPlaySlide.value) {
			const singPlayEl = container.querySelector('#paper')
			if (singPlayEl) {
				pagePenSize = {
					canvasWidth: container.clientWidth,
					canvasHeight: singPlayEl.scrollHeight * slideScale.value,
					containerWidth: container.clientWidth,
					containerHeight: singPlayEl.clientHeight * slideScale.value
				}
			}
		}
		const pagePen: IPagePen = {
			id: currentSlide.value.id,
			pagePenSize
		}
		sendToHost('pen-create-new-page', pagePen)
	}

	const handlePenSingPlayScroll = (
		scrollTop: number,
		isPlay: boolean,
		isFirst = false
	) => {
		isPptSingPlay.value = isPlay
		newScrollTop = scrollTop
		sendToHost('pen-sing-play-scroll', {
			scrollTop: scrollTop * slideScale.value,
			isPlay,
			isFirst
		})
	}
	const changePptSingPlay = (isPlay: boolean) => {
		isPptSingPlay.value = isPlay
	}

	watch(
		() => isPenOpened.value,
		(newVal) => {
			newVal && sendPenStage()
			newVal && (isPenDraw.value = true) // 打开默认为画笔模式
			if (newVal) {
				sendToHost('scroll-top', newScrollTop)
			}
		}
	)

	return {
		isPenOpened,
		sendPenStage,
		handlePenSingPlayScroll,
		changePptSingPlay,
		setSlideScale
	}
}
