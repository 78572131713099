import useIpcRenderer from '@/hooks/useIpcRenderer'
import { useStore } from '@/store'
import { ElementTypes, ElfElementTypes, Slide } from '@/types/slides'
import logger from '@evideo/logger'
import { computed, ref } from 'vue'

// !旧版到了多标签上线 稳定后要逐步删掉
interface PageCanvasSize {
    canvasWidth: number | string
    canvasHeight: number | string
    containerWidth: number | string
    containerHeight: number | string
}

interface PptPageSize {
    id: string
    pageCanvasSize: PageCanvasSize
}

let ipcRenderer: any

if (window.require) {
    ipcRenderer = window.require('electron').ipcRenderer
}
// 用来PPTid和画布尺寸
let pptPageCanvases: PptPageSize[] = []
// 手的状态
let handShow = false
// 笔的状态
const pencilState = ref(false)

let isPlay = false

export default () => {
    const store = useStore()
    const { ipcRenderSendToHost } = useIpcRenderer()

    const screenType = computed(() => store.state.screenType)
    const slides = computed(() => store.state.slides)
    const currentIndex = computed(() => store.state.slideIndex)
    const currentSlide = computed<Slide>(() => store.getters.currentSlide)
    const isCurrentSingPlaySlide = computed(
        () =>
            currentSlide.value.elements.findIndex(
                (el) =>
                    el.type === ElementTypes.ELF &&
                    el.subtype === ElfElementTypes.SING_PLAY
            ) > -1
    )

    const windowInnerWidth = window.innerWidth
    const windowInnerHeight = window.innerHeight

    const defaultSize: PageCanvasSize = {
        containerWidth: windowInnerWidth,
        containerHeight: windowInnerHeight - 80,
        canvasWidth: windowInnerWidth,
        canvasHeight: windowInnerHeight - 80
    }

    // 初始化画布id与尺寸
    const initPptCanvasList = () => {
        pptPageCanvases = slides.value.map((item) => ({
            id: item.id,
            pageCanvasSize: defaultSize
        }))
    }

    // 通知主线程electron的画布及canvas尺寸
    const sendContainerSize = () => {
        const isDemonstrateFullScreen =
            screenType.value === 'demonstrate' ? true : false
        if (!isDemonstrateFullScreen) {
            return
        }
        initPptCanvasList()
        // 当前点击的页
        if (isCurrentSingPlaySlide.value) {
            sendSvgHeight()
        } else {
            pptPageCanvases[currentIndex.value] &&
                ipcRenderSendToHost('setCanvasPPT', [
                    pptPageCanvases[currentIndex.value]
                ])
        }
    }

    // 切换页面
    const sendChangePage = () => {
        // 判断当前是否为全屏
        const isDemonstrateFullScreen =
            screenType.value === 'demonstrate' ? true : false
        if (isDemonstrateFullScreen) {
            // 即将切换到的一页的
            const nextScreenId = currentSlide.value.id
            const pageCanvas = pptPageCanvases.find((item) => {
                return item.id === nextScreenId
            })
            pageCanvas && ipcRenderSendToHost('setCanvasPPT', [pageCanvas])
        }
    }

    // 设置曲谱的宽高尺寸
    const sendSvgHeight = () => {
        const dom = document.getElementById('paper')
        if (dom) {
            const canvasHeight = dom.scrollHeight
            const containerHeight = dom.offsetHeight
            const canvasWidth = dom.clientWidth
            const containerWidth = dom.clientWidth
            pptPageCanvases[currentIndex.value].pageCanvasSize = {
                containerWidth: containerWidth,
                containerHeight: containerHeight,
                canvasWidth: canvasWidth,
                canvasHeight: canvasHeight
            }
        }
        ipcRenderSendToHost('isMusicList', [
            pptPageCanvases[currentIndex.value]
        ])
    }

    // 接收开启笔信息，并重新传送高度
    const handlePencilState = () => {
        try {
            ipcRenderer.on('setPencil', (event: any, message: boolean) => {
                pencilState.value = message
                if (!pencilState.value) {
                    handShow = false
                }
                logger.info(`pencil _pencil state: ${pencilState.value}`)

                if (screenType.value === 'demonstrate') {
                    if (message) {
                        reOpenPencil()
                    }
                }
            })
        } catch (err) {
            logger.info(`openPencil发送失败`, err)
        }
    }

    // 开始放映时，监听electron的笔状态
    const sendIsPencilOpen = () => {
        ipcRenderSendToHost('isPencilOpen', ['handleState'])
    }

    // electron通知曲谱进行滚动
    const CanvasScrollOn = () => {
        const dom = document.querySelector<HTMLElement>('#paper')
        try {
            ipcRenderer.on('canvasScroll', (event: any, message: any) => {
                if (dom && !handShow && !isPlay) {
                    dom.scrollTop = message[0]
                }
            })
        } catch (err) {
            logger.info(`canvasScroll发送失败`, err)
        }
    }

    // electron通知手的状态
    const handShowState = () => {
        try {
            ipcRenderer.on('handState', (event: any, message: any) => {
                handShow = message
                logger.info(`pencil hand state: ${handShow}`)
            })
        } catch (err) {
            logger.info(`handShowState发送失败`, err)
        }
    }

    // electron重新打开笔时再次设置状态
    const reOpenPencil = () => {
        // fixme: 暂时先定死，只要有courseware-preview-ppt-hide的class代表ppt隐藏了 就不发送
        if (document.querySelector('.courseware-preview-ppt-hide')) {
            return
        }
        sendSvgHeight()
        pptPageCanvases[currentIndex.value] &&
            ipcRenderSendToHost('setCanvasPPT', [
                pptPageCanvases[currentIndex.value]
            ])
    }

    // 传送曲谱当前滚动位置
    const sendCurrentTop = (top: number, isPlay: boolean, isFirst: boolean) => {
        ipcRenderSendToHost('setCurrentTop', {
            top,
            isPlay,
            isFirst
        })
    }

    /**
     * 处理曲谱滚动，对于笔的画布滚动
     * @param scrollTop
     */
    const handleSingPlayScroll = (
        scrollTop: number,
        _isPlay: boolean,
        isFirst = false
    ) => {
        isPlay = _isPlay
        sendCurrentTop(scrollTop, isPlay, isFirst)
    }
    const changeIsPlay = (_isPlay: boolean) => {
        isPlay = _isPlay
    }

    // 释放笔并通知关闭笔
    const disposePptCanvasList = () => {
        pptPageCanvases.length = 0
        ipcRenderSendToHost('closePencil', [false])
    }

    return {
        pencilState,
        sendContainerSize,
        sendChangePage,
        sendSvgHeight,
        handleSingPlayScroll,
        changeIsPlay,
        disposePptCanvasList,
        handlePencilState,
        CanvasScrollOn,
        handShowState,
        sendIsPencilOpen
    }
}
