import useRunTime from './useRunTime'
import { ToolBarTab } from '@/configs/toolbar'
import { computed } from 'vue'

export type DynamicTabsMap = {
	label: string
	value: string
	component: any
	tabType: string
}

export default () => {
	const { runtime } = useRunTime()

	const dynamicEditorTabs = computed(() => runtime.dynamicEditorTabs)
	const dynamicPreviewerTabs = computed(() => runtime.dynamicPreviewerTabs)

	/**
	 * 过滤条件
	 */
	const filterDynamicTabs = (tabs: any[]): Array<ToolBarTab> => {
		const canRenderTabs = tabs.reduce((preTabs, curTabs) => {
			preTabs.push(curTabs)
			return preTabs
		}, [])
		return canRenderTabs || []
	}

	// 编辑模式动态tab输出
	const editorCurrentTabs = computed(() => {
		return filterDynamicTabs(dynamicEditorTabs.value)
	})

	// 预览模式动态tab输出
	const previewerCurrentTabs = computed(() => {
		return filterDynamicTabs(dynamicPreviewerTabs.value)
	})

	return {
		previewerCurrentTabs,
		editorCurrentTabs
	}
}
