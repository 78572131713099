import logger from '@evideo/logger'
import { computed } from 'vue'

declare global {
	interface Window {
		WebViewJavascriptBridge: {
			registerHandler: (
				key: string,
				callBack: (data: any, responseCallback: (data: any) => void) => void
			) => void
			callHandler: (
				key: string,
				prams?: any,
				callBack?: (data: any) => void
			) => void
		}
	}
}
export interface JsBridgeObject {
	success: boolean
	eventCode: number
	data: any
}

/**
 * 转换jsbridge返回的结果，补充一定的结果
 * @param {*} result
 * @returns
 */
const parseJsBridgeResult = (result: string) => {
	const res = JSON.parse(result) || {}
	res.success = res.eventCode > 0
	return Object.assign(
		{ success: false, eventCode: 0, data: null },
		res
	) as JsBridgeObject
}

// /**
//  * 转换给Jsbrdge的结果
//  * @param {*} result
//  * @returns
//  */
// const stringifyJsBridgeResult = (result: any) => {
// 	const res = Object.assign(
// 		{ success: true, eventCode: 1, data: null },
// 		result || {}
// 	)
// 	return JSON.stringify(res)
// }

const isRegisterAndroidKeyDown = false
const androidKeyDownListenerMap: any[] = []

export default () => {
	const jsBridge = computed(() => window.WebViewJavascriptBridge)

	const checkJsBridge = computed(() => !!jsBridge.value)

	/**
	 * 获取登录学生主机信息
	 * @returns
	 */
	const getStudentDevices: () => Promise<any[]> = () => {
		return new Promise((resolve) => {
			let studentDevices: any[] = []
			checkJsBridge.value &&
				jsBridge.value.callHandler('getStudentDevices', null, (data) => {
					const res = parseJsBridgeResult(data || '{}')
					res.success && (studentDevices = res.data)
					res.success && resolve(studentDevices)
					!res.success && resolve(studentDevices)
				})
			!checkJsBridge.value && resolve(studentDevices)
			// !checkJsBridge.value && resolve([
			//   { sn: 1, ip: '12311', user: '许小明', groupId: 2, groupName: '分组2' },
			//   { sn: 2, ip: '12312', user: '许小明', groupId: 2, groupName: '分组2' },
			//   { sn: 3, ip: '12311', user: '许小明', groupId: 3, groupName: '分组1' },
			//   { sn: 4, ip: '12312', user: '许小明', groupId: 3, groupName: '分组1' },
			//   { sn: 5, ip: '12311', user: '许小明', groupId: 1, groupName: '分组3' },
			//   { sn: 6, ip: '12312', user: '许小明', groupId: 1, groupName: '分组3' },
			//   { sn: 7, ip: '12313', user: '许小明', groupId: 0, groupName: '未分组' },
			//   { sn: 8, ip: '12313', user: '许小明', groupId: 0, groupName: '未分组' },
			//   { sn: 9, ip: '12313', user: '许小明', groupId: 0, groupName: '未分组' }

			// ])
		})
	}

	/**
	 * 下发任务
	 * @param data
	 * @returns
	 */
	const issureTask: (data: any) => Promise<any> = (data) => {
		return new Promise((resolve) => {
			checkJsBridge.value &&
				jsBridge.value.callHandler('issureTask', data, (data) => {
					const res = parseJsBridgeResult(data || '{}')
					res.success && resolve(true)
					!res.success && resolve(false)
				})
			!checkJsBridge.value && resolve(false)
			// !checkJsBridge.value && setTimeout(() => {
			//   resolve(true)
			// }, 3000)
		})
	}

	/**
	 * 开始任务
	 */
	const beginIssureTask: () => Promise<any[]> = () => {
		return new Promise((resolve) => {
			let studentScores: any[] = []
			checkJsBridge.value &&
				jsBridge.value.callHandler('beginIssureTask', null, (data) => {
					const res = parseJsBridgeResult(data || '{}')
					res.success && (studentScores = res.data)
					res.success && resolve(studentScores)
					!res.success && resolve(studentScores)
				})
			!checkJsBridge.value && resolve(studentScores)
			// !checkJsBridge.value && setTimeout(() => {
			//   resolve([{ user: '许华乐', score: 98 }, { user: '许华乐', score: 93 }, { user: '许华乐', score: 94.5 }])
			// }, 3000)
		})
	}

	/**
	 * 停止任务
	 * @returns
	 */
	const stopIssureTask: () => Promise<boolean> = () => {
		return new Promise((resolve) => {
			checkJsBridge.value &&
				jsBridge.value.callHandler('stopIssureTask', null, (data) => {
					const res = parseJsBridgeResult(data || '{}')
					res.success && resolve(true)
					!res.success && resolve(false)
				})
			!checkJsBridge.value && resolve(false)
			// !checkJsBridge.value && resolve(true)
		})
	}

	const closeStudentPage = () => {
		checkJsBridge.value && jsBridge.value.callHandler('closeStudentPage')
	}

	const openWebView = (url: string) => {
		return new Promise((resolve) => {
			checkJsBridge.value &&
				jsBridge.value.callHandler('openWebView', url, (data) => {
					const res = parseJsBridgeResult(data || '{}')
					res.success && resolve(true)
					!res.success && resolve(false)
				})
			!checkJsBridge.value && resolve(false)
		})
	}

	const registerAndroidKeyDown = (keyDown: any) => {
		const keyDownMap = androidKeyDownListenerMap
		const len = keyDownMap.filter((i) => i.id === keyDown.id).filter(Boolean)
			.length
		if (len <= 0) {
			keyDownMap.push(keyDown)
		}
		!isRegisterAndroidKeyDown &&
			checkJsBridge.value &&
			jsBridge.value.registerHandler('androidKeyDownListener', (data) => {
				const res = parseJsBridgeResult(data || '{}')
				const { key } = res.data || {}
				!key && logger.warn('接收键盘事件错误，没有key值', res.data)
				const anyKeyArray =
					keyDownMap.filter((i) => !i.key).filter(Boolean) || []
				anyKeyArray.forEach((key) => {
					key.func(res.data)
				})
				const keyArray =
					keyDownMap
						.filter((i) => i.key && i.key.toUpperCase() === key.toUpperCase())
						.filter(Boolean) || []
				keyArray.forEach((key) => {
					key.func(res.data)
				})
			})
	}

	const registerAndroidEvent = (event: any, callBack: any) => {
		checkJsBridge.value && jsBridge.value.registerHandler(event, callBack)
	}

	return {
		checkJsBridge,
		getStudentDevices,
		issureTask,
		beginIssureTask,
		stopIssureTask,
		closeStudentPage,
		openWebView,
		registerAndroidKeyDown,
		registerAndroidEvent
	}
}
