import logger from '@evideo/logger';
import useShowMessage from './useShowMessage';
import useFetchApi from '@/hooks/useFetchApi';

export default () => {
  const { showMeassage } = useShowMessage();
  const { fetchApi } = useFetchApi();

  const getDetail = async (path: string, params?: Record<string, any>) => {
    if (!path) {
      throw new Error('path 不能为空');
    }
    const res = await fetchApi({
      url: `https://${process.env.VUE_APP_RESOURCE_CENTER_HOST}${path}`,
      method: 'get',
      params,
      timeout: 3000,
    });
    if (res.status !== 200) {
      logger.error(res);
      showMeassage('获取资源失败，请检查资源是否存在或重新加载ppt', 'error');
      throw new Error(`获取资源失败详情失败[${path}]`);
    }
    return res.data;
  };
  const getExamCenterDetail = async (
    path: string,
    params?: Record<string, any>
  ) => {
    if (!path) {
      throw new Error('path 不能为空');
    }
    const res = await fetchApi({
      url: `https://${process.env.VUE_APP_EXAM_CENTER_HOST}${path}`,
      method: 'get',
      params,
      timeout: 3000,
    });

    if (res.status !== 200) {
      logger.error(res);
      throw new Error(`获取资源失败详情失败[${path}]`);
    }
    return res.data;
  };

  /**
   * 获取欣赏歌曲详情
   * @param id 歌曲id
   */
  const getEnjoySongDetail = async (id: number, source = 'official') => {
    return await getDetail(`/${source}/enjoy-song/${id}`);
  };

  /**
   * 获取谱例歌曲详情
   * @param id 歌曲id
   */
  const getSheetSongDetail = async (id: number, source = 'official') => {
    return await getDetail(`/${source}/sheet-song/${id}`);
  };

  /**
   * 获取音乐律动详情
   * @param id 歌曲id
   */
  const getMusicRhythmDetail = async (id: number, source = 'official') => {
    return await getDetail(`/${source}/music-rhythms/${id}`);
  };

  /**
   * 获取知识点详情
   * @param id 歌曲id
   */
  const getKnowledgePointsDetail = async (id: number, source = 'official') => {
    return await getDetail(`/${source}/knowledge-point/${id}`);
  };

  /**
   * 获取课件详情
   * @param id 课件id
   * @param source 课件来源
   * @returns
   */
  const getCoursewareDetail = async (id: number, source = 'official') => {
    return await getDetail(`/${source}/courseware/${id}`);
  };
  /**
   * 获取题目详情
   * @param id 歌曲id
   */
  const getQuestionsDetail = async (id: number, source = 'official') => {
    return await getExamCenterDetail(`/${source}/demo-question/${id}`);
  };

  /**
   * 获取素材库分类列表
   * @param material_type
   * @param source
   */
  const getMaterialCategories = async (
    material_type: 'video' | 'image' | 'audio',
    source = 'official'
  ) => {
    return await getDetail(`/${source}/material-categories`, {
      material_type,
      page: 1,
      per_page: 9999,
    });
  };

  /**
   * 获取素材库列表
   * @param material_type
   * @param source
   * @param params
   */
  const getMaterialLibrary = async (
    material_type: 'video' | 'image' | 'audio',
    source = 'official',
    params?: Record<string, any>
  ) => {
    return await getDetail(`/${source}/materials`, {
      material_type,
      status: 1,
      ...(params || {}),
    });
  };

  return {
    getEnjoySongDetail,
    getSheetSongDetail,
    getKnowledgePointsDetail,
    getCoursewareDetail,
    getMusicRhythmDetail,
    getQuestionsDetail,
    getMaterialCategories,
    getMaterialLibrary,
  };
};
