import { compressAccurately, EImageType } from 'image-conversion'

export default () => {
  const compressImage = async (file: File, size: number) => {
    const imageName = file.name
    const imageType = file.type
    if (imageType.includes('png')) {
      const imageBlob = await compressAccurately(file, {
        size,
        type: EImageType.PNG
      })
      const imageFile = new File([imageBlob], imageName, { type: imageType })
      return imageFile
    }
    const imageBlob = await compressAccurately(file, size)
    const imageFile = new File([imageBlob], imageName, { type: imageType })
    return imageFile
  }

  return {
    compressImage
  }
}