import useQianKunEventCenter from './useQianKunEventCenter'
import useRunTime from './useRunTime'
import { useStore } from '@/store'
import { computed } from 'vue'
import { State } from '@/store/state'

export interface PPT_EDIT_ATTR {
  type?: string
  element_id?: string
  element_type?: string
  subtype?: string
  courseware_id?: string
  ppt_url?: string
  sid?: string
}

/** 数据上报 模块id */
export enum STAT_MODULE_ID {
  PPT_MODULE_ID = 'ppt'
}

/** 数据上报 页面d */
export enum STAT_PAGE {
  PPT_EDIT_PAGE_ID = 'ppt_edit'
}

export enum STAT_EVENT {
  PPT_EDIT_TOOLBTN_CLICK = 'ppt_edit_toolBtn_click',
  PPT_EDIT_ELEMENT_CREATE = 'ppt_edit_element_create',
  PPT_EDIT_ELEMENT_DELETE = 'ppt_edit_element_delete'
}

export default (_state?: State) => {
  const {statReport} = useQianKunEventCenter()
  const {runtime} = useRunTime()
  const store = useStore()

  const ppt_url = computed(() => (store ? store.state.pptUrl : _state?.pptUrl))

  const pptEditStatReport = (eventid: STAT_EVENT, eventattr: PPT_EDIT_ATTR) => {
    const {extData = {}} = runtime
    const _eventattr = {
      courseware_id: extData.coursewareId,
      ppt_url: ppt_url.value,
      ...eventattr
    }
    statReport({
      moduleid: STAT_MODULE_ID.PPT_MODULE_ID,
      pageid: STAT_PAGE.PPT_EDIT_PAGE_ID,
      eventid,
      eventattr: _eventattr as any
    })
  }

  return {
    pptEditStatReport
  }
}
