import { ActionTypes, MutationTypes, useStore } from '@/store'
import useHistorySnapshot from './useHistorySnapshot'
import { decrypt } from '@/utils/crypto'
import { createElementIdMap } from '@/utils/element'
import { computed, toRaw } from 'vue'
import { Slide } from '@/types/slides'
import { v4 as uuidv4 } from 'uuid'
import useQianKun from './useQianKun'
import useQianKunEventCenter from './useQianKunEventCenter'
import usePptxToJson from './usePptxToJson'
import logger from '@evideo/logger'

export default () => {
	const store = useStore()
	const { addHistorySnapshot } = useHistorySnapshot()
	const { isMicroApp } = useQianKun()
	const { notifyMessage } = useQianKunEventCenter()
	const { parsePptx } = usePptxToJson()

	const currentSlide = computed(() => store.getters.currentSlide)
	const editMode = computed(() => store.state.editMode)
	const importJson = (content: string) => {
		return new Promise<void>((resolve, reject) => {
			try {
				const json = JSON.parse(content)
				if (!json.slides) {
					reject(new Error('Invalid JSON'))
				}
				store.commit(MutationTypes.LOAD_PPT, {
					...JSON.parse(content),
					uuid: undefined
				})
				addHistorySnapshot()
				resolve()
			} catch (e) {
				reject(e)
			}
		})
	}

	const initKnowledgePoint = () => {
		store.commit(MutationTypes.SET_UUID, uuidv4())
		store.commit(MutationTypes.LOAD_PPT, {
			slides: [
				{
					id: uuidv4(),
					elements: [],
					background: {
						type: 'solid',
						color: '#ffffff'
					}
				}
			]
		})
		store.dispatch(ActionTypes.INIT_SNAPSHOT_DATABASE)
	}
	const importKnowledgePoints = (content: string) => {
		if (content === '') {
			initKnowledgePoint()
			return
		}
		// 知识点编辑 默认的要去掉
		// if (editMode.value === 'knowledgePoints') {
		//   currentSlide.value.id =unde
		//   currentSlide.value.elements =[]
		// }

		let decryptContent: string | undefined
		try {
			decryptContent = decrypt(content)
		} catch (e) {
			logger.warn('解密知识点失败', e)
			decryptContent = undefined
		}
		if (!decryptContent || decryptContent === 'undefined') {
			isMicroApp &&
				notifyMessage({
					type: 'error',
					message: '解密知识点失败'
				})
			initKnowledgePoint()
			return
		}
		const points: Slide = JSON.parse(decryptContent!)

		// 处于ppt编辑导入才重新设置id
		if (editMode.value !== 'knowledgePoints') {
			const { groupIdMap, elIdMap } = createElementIdMap(points.elements)
			// 重新设置元素id
			for (const element of points.elements) {
				element.id = elIdMap[element.id]
				if (element.groupId) element.groupId = groupIdMap[element.groupId]
			}
			if (points.animations) {
				for (const animation of points.animations) {
					animation.elId = elIdMap[animation.elId]
				}
			}
			const curSlide: Slide = { ...toRaw(currentSlide.value) }
			points.elements = points.elements.concat(curSlide.elements || [])
		}
		store.commit(MutationTypes.UPDATE_SLIDE, points)

		// 处于ppt编辑导入才设置撤销 重做
		if (editMode.value !== 'knowledgePoints') {
			addHistorySnapshot()
		} else {
			store.commit(MutationTypes.SET_UUID, uuidv4())
			store.dispatch(ActionTypes.INIT_SNAPSHOT_DATABASE)
		}
	}
	/**
	 * 导入原生的ppt文件
	 * @param file
	 * @returns
	 */
	const importPptx = (file: File): Promise<any> => {
		return new Promise((resolve) => {
			const handleJson = async () => {
				const uuid = uuidv4()
				store.commit(MutationTypes.SET_UUID, uuid)
				try {
					logger.time(`ppt-file(${file.name}) parse time`)
					const pptJsonData = await parsePptx(file)
					logger.timeEnd(`ppt-file(${file.name}) parse time`)

					const pptJsonDataStr = JSON.stringify(pptJsonData)
					return pptJsonDataStr
				} catch (e: any) {
					logger.error('PPT文件解析异常', e)
					throw new Error(e)
				}
			}
			const pptxReg = /\.(pptx)$/
			const pptReg = /\.(ppt)$/
			if (pptReg.test(file.name)) {
				throw new Error('无法解析.ppt格式文件，请使用wps转换为.pptx文件后上传')
			}
			if (!pptxReg.test(file.name)) {
				throw new Error('文件错误，请上传.pptx格式文件')
			}
			const data = handleJson()
			resolve(data)
		})
	}

	return {
		importJson,
		importKnowledgePoints,
		importPptx
	}
}
