/*
 * @description: 自动监听父级页面传递的ppturl 请求数据 加载ppt
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-08-24 17:55:58
 * @lastEditors: xuhuale
 * @lastEditTime: 2023-03-31 17:00:46
 */

import useImport from '@/hooks/useImport'
import { ActionTypes, MutationTypes, useStore } from '@/store'
import { v4 as uuidv4 } from 'uuid'
import useExport from './useExport'
import usePageLoading from './usePageLoading'
import useQianKunEventCenter from './useQianKunEventCenter'
// import { destoryMicoAppEle } from '@/views/components/element/ElfElement/MicroAppElement/microapps'
import { State } from '@/store/state'
import emitter, { EmitterEvents } from '@/utils/emitter'
import logger from '@evideo/logger'
import { nextTick } from 'vue'
import useGlobalPencil from './useGlobalPencil'
import usePreviewImage from './usePreviewImage'
import useRunTime from './useRunTime'
import { generateSlidePreviewStates } from '@/hooks/usePreviewImage'
import _ from 'lodash'

export default () => {
    const store = useStore()
    const {
        registerLoadPPt,
        registerResetPPt,
        registerPptHide,
        registerLoadKnowledgePoints
    } = useQianKunEventCenter()

    const { pptGenerateSlidePreview } = useExport()
    const { generateSlidePreview } = usePreviewImage()
    const { sendContainerSize } = useGlobalPencil()

    const resetPpt = () => {
        store.commit(MutationTypes.RESET)
        logger.info('清空重置Ppt成功')
    }
    /**
     * 加载ppt
     * @param url
     */
    const loadPpt = async (
        url: string | undefined = undefined,
        state: State | any = {}
    ) => {
        generateSlidePreviewStates.value = {}
        const { showLoading, closeLoading } = usePageLoading()
        showLoading('加载课件中...', 10000)
        // await destoryMicoAppEle()
        if (url) {
            store.commit(MutationTypes.LOAD_PPT, {})
            store.commit(MutationTypes.SET_UUID, uuidv4())
            const content = await fetch(
                url.replaceAll('+', '%2B')
            ).then((res) => res.json())
            const _slides = _.cloneDeep(content?.slides)

            store.commit(
                MutationTypes.LOAD_PPT,
                Object.assign({}, content || {}, { pptUrl: url, ...state })
            )
            closeLoading()
            nextTick()
                .then(async () => {
                    return await pptGenerateSlidePreview(_slides)
                })
                .then(() => {
                    store.dispatch(ActionTypes.INIT_SNAPSHOT_DATABASE)
                })
        } else {
            // 初始化新的uuid
            store.commit(MutationTypes.SET_UUID, uuidv4())
            store.commit(MutationTypes.LOAD_PPT, {})
            store.dispatch(ActionTypes.INIT_SNAPSHOT_DATABASE)
            // ! 空白生成预览图,保证放映时有预览图
            generateSlidePreview()
            closeLoading()
        }
        sendContainerSize()
        emitter.emit(EmitterEvents.ON_LOAD_PPT, url)
        registerPptHide()
        registerResetPPt(resetPpt)
    }

    /**
     * iframe接收url自动加载ppt
     */
    const outsideLoadPpt = () => {
        const { configRunTime } = useRunTime()
        registerLoadPPt(async ({ data: res, event: e }: any) => {
            const { url, screening, screenType, runtime = {} } = res || {}
            if (
                runtime &&
                (runtime?.editPptRule?.mode === 'onlyPpt' ||
                    runtime?.editPptRule?.mode === 'multipleTabs')
            ) {
                store.state.editingScreening = true
            }
            configRunTime(runtime)
            await loadPpt(url, runtime.state || {})
            if (screening && screenType) {
                store.commit(MutationTypes.SET_SCREENING, {
                    screening,
                    screenType
                })
            }
            e.reply(true)
        })
    }

    const outsideLoadKnowledgePoints = () => {
        const { importKnowledgePoints } = useImport()
        const { showLoading, closeLoading } = usePageLoading()
        registerLoadKnowledgePoints(({ data: res, event: e }: any) => {
            const { content, screening, screenType } = res || {}
            try {
                showLoading('加载知识中...', 10000)
                importKnowledgePoints(content)
                if (screening && screenType) {
                    store.commit(MutationTypes.SET_SCREENING, {
                        screening,
                        screenType
                    })
                }
            } catch (e) {
                logger.warn('自动加载知识点出错', e)
            } finally {
                closeLoading()
                e.reply(true)
            }
        })
    }
    return {
        loadPpt,
        outsideLoadPpt,
        outsideLoadKnowledgePoints
    }
}
