import useWindowPostMessage from '@/hooks/useWindowPostMessage'
import useElectron from '@/hooks/useElectron'

/** 是否处于Electron下 */
export const IsInElectron = !!window.__ELECTRON__
/** 是否处于Android */
export const IsInAndroid = !!window.WebViewJavascriptBridge
/** 是否处于原生环境下 */
export const IsInNativeClient =
	window.__ELECTRON__ || window.WebViewJavascriptBridge
/** 是否处于浏览器环境下 */
export const IsInBrowser = !IsInNativeClient

const url = new URL(window.location.href)
const power = url.searchParams.get('power')
const isPowerByTabPage = power === 'tab-page'

export default () => {
	const {
		addEventListener,
		removeEventListener,
		sendToHost,
		removeAllEventListener
	} = IsInElectron ? useElectron() : useWindowPostMessage()

	return {
		isPowerByTabPage,
		removeAllReceiveHostMessage: removeAllEventListener,
		receiveHostMessage: addEventListener,
		removeReceiveHostMessage: removeEventListener,
		sendToHost
	}
}
