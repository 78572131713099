import { useStore } from '@/store'
import { computed } from 'vue'
import axios, { AxiosRequestConfig } from 'axios'
export default () => {
    const store = useStore()
    const token = computed(() => store.state.token)
    const fetchApi = (
        config: AxiosRequestConfig,
        options?: {
            /** 请求之前，设置头部信息之前，可以直接修改config */
            beforeSetRequestHeaders?: (config: any) => void
            /** 请求之前，设置头部信息之后，可以直接修改config */
            afterSetRequestHeaders?: (config: any) => void
            /** 得到响应之后 */
            afterResponse?: (res: any) => void
        }
    ) => {
        const service = axios.create({})
        const setRequestHeaders = (config: any) => {
            if (
                config.certificate !== false ||
                !!(
                    new URLSearchParams(config.url).get('certificate') ===
                    'true'
                )
            ) {
                Object.entries({
                    Authorization: `Bearer ${token.value}`,
                    // 特殊处理：当delete|put方法时，用这个告诉服务端重写请求类型，传输层面的类型统一替换为POSt
                    // 场景：部分服务器无法直接支持delete|put时
                    'X-Http-Method-Override': config._method,
                    'Cache-Control': 'no-cache',
                    'Cache-control': 'no-store',
                    Pragma: 'no-cache', // in http 1.0 = Cache-Control
                    Expires: 1 // 有效值
                }).forEach(([key, val]) => {
                    if (val) {
                        config.headers && (config.headers[key] = val)
                    }
                })
            } else {
                if (config.headers) {
                    config!.headers['X-Http-Method-Override'] = config._method
                }
            }
            return config.headers
        }
        service.interceptors.request.use(
            (config: any) => {
                if (options && options.beforeSetRequestHeaders) {
                    options.beforeSetRequestHeaders(config)
                }
                config.headers = setRequestHeaders(config as any)
                if (options && options.afterSetRequestHeaders) {
                    options.afterSetRequestHeaders(config)
                }

                return config
            },
            (error) => {
                return Promise.reject(error)
            }
        )
        service.interceptors.response.use(
            (response) => {
                if (options && options.afterResponse) {
                    options.afterResponse(response)
                }
                return response
            },
            (error) => {
                return Promise.reject(error)
            }
        )
        return service(config)
    }

    return { fetchApi }
}
