import { computed } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { PPTElement, Slide } from '@/types/slides'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import useStatReport, { STAT_EVENT } from './useStatReport'

export default () => {
	const store = useStore()
	const activeElementIdList = computed(() => store.state.activeElementIdList)
	const activeGroupElementId = computed(() => store.state.activeGroupElementId)
	const currentSlide = computed<Slide>(() => store.getters.currentSlide)

	const { addHistorySnapshot } = useHistorySnapshot()
	const { pptEditStatReport } = useStatReport()

	const updateElementAnimations = (elements: PPTElement[] = []) => {
		if (
			currentSlide.value.animations &&
			currentSlide.value.animations.length > 0
		) {
			const _animations =
				currentSlide.value.animations
					.map((an) => {
						if (elements.map((el) => el.id).includes(an.elId)) {
							return an
						}
					})
					.filter(Boolean) || []
			_animations.forEach((_an) => {
				if (_an?.triggerSource) {
					if (!elements.map((el) => el.id).includes(_an.triggerSource)) {
						delete _an.triggerSource
					}
				}
			})

			return _animations
		}
	}
	// 删除全部选中元素
	// 组合元素成员中，存在被选中可独立操作的元素时，优先删除该元素。否则默认删除所有被选中的元素
	const deleteElement = () => {
		if (!activeElementIdList.value.length) return

		let newElementList: PPTElement[] = []
		let olElementList: PPTElement[] = []
		if (activeGroupElementId.value) {
			newElementList = currentSlide.value.elements.filter(
				(el) => el.id !== activeGroupElementId.value
			)
			olElementList = currentSlide.value.elements.filter(
				(el) => el.id === activeGroupElementId.value
			)
		} else {
			newElementList = currentSlide.value.elements.filter(
				(el) => !activeElementIdList.value.includes(el.id)
			)
			olElementList = currentSlide.value.elements.filter((el) =>
				activeElementIdList.value.includes(el.id)
			)
		}

		store.commit(MutationTypes.SET_ACTIVE_ELEMENT_ID_LIST, [])
		const newAnimations = updateElementAnimations(newElementList)
		store.commit(MutationTypes.UPDATE_SLIDE, {
			elements: newElementList,
			animations: newAnimations
		})
		addHistorySnapshot()
		olElementList.forEach((el) => {
			pptEditStatReport(STAT_EVENT.PPT_EDIT_ELEMENT_DELETE, {
				element_id: el.id,
				element_type: el.type,
				subtype: (el as any).subtype,
				sid: (el as any).sid
			})
		})
	}

	// 删除内面内全部元素(无论是否选中)
	const deleteAllElements = () => {
		if (!currentSlide.value.elements.length) return
		store.commit(MutationTypes.SET_ACTIVE_ELEMENT_ID_LIST, [])
		store.commit(MutationTypes.UPDATE_SLIDE, {
			elements: [],
			animations: undefined
		})
		addHistorySnapshot()
	}

	return {
		deleteElement,
		deleteAllElements
	}
}
