import useExport from '@/hooks/useExport'
import { watch, ref, computed } from 'vue'
import { store } from '@/store'
import { PptState } from '@/types/pptState'
import logger from '@evideo/logger'

export default () => {
	const { autoSaveToCloud, isSaving, pptSaveAbort } = useExport()
	let saveTimeoutId: any = undefined
	const isAutoSave = computed(() => !!saveTimeoutId) // 自动保存是否开启
	const pptState = computed(() => store.state.pptState)
	const checkAutoSaveAbort = computed(() => pptSaveAbort.value)
	const checkStartAutoSaveTimeId = ref<any>(undefined) // 30分钟检查是否关闭自动保存
	const checkIsAutoSaving = ref(false) // 当前是否正在自动保存ppt
	let pptStates: string[] = [] // 保存30分钟内ppt有无修改状态
	let saveTime = 300000 // 默认5分钟一次自动保存
	let repeat = 6 // 默认30分钟检查一次 30 / 5 = 6

	watch(
		() => isSaving.value,
		() => {
			if (saveTimeoutId && isSaving.value) {
				checkIsAutoSaving.value = true
			} else {
				checkIsAutoSaving.value = false
			}
		},
		{ immediate: true }
	)
	watch(
		() => saveTimeoutId,
		() => {
			if (saveTimeoutId && isSaving.value) {
				checkIsAutoSaving.value = true
			} else {
				checkIsAutoSaving.value = false
			}
		},
		{ immediate: true }
	)

	watch(
		() => pptState.value,
		() => {
			if (
				!saveTimeoutId &&
				pptState.value === PptState.MODIFY &&
				!checkStartAutoSaveTimeId.value
			) {
				// logger.info('ppt有修改,开启ppt自动保存')
				// startAutoSavePpt(saveTime)
			}
		}
	)
	// 重置自动保存检查配置
	const resetCheckCloseAutoSave = () => {
		checkStartAutoSaveTimeId.value &&
			clearInterval(checkStartAutoSaveTimeId.value)
		checkStartAutoSaveTimeId.value = undefined
		repeat = 6
		pptStates = []
	}

	// 检查是否关闭自动保存
	const checkCloseAutoSaveOrNot = (time: number) => {
		checkStartAutoSaveTimeId.value = setInterval(() => {
			if (repeat === 0) {
				if (isAutoSave.value && !pptStates.includes(PptState.MODIFY)) {
					logger.info('30分钟内ppt无修改,关闭ppt自动保存')
					// 自动保存开启 且 30分钟内没有修改 => 关闭自动保存
					closeAutoSavePpt()
				} else if (isAutoSave.value) {
					logger.info('30分钟内ppt有修改,继续一轮30分钟检查')
					// 自动保存开启 ,30分钟内有过修改 => 继续新一轮30分钟后检查
					checkCloseAutoSaveOrNot(saveTime)
				}
				resetCheckCloseAutoSave()
			} else {
				pptStates.push(pptState.value)
				repeat--
			}
		}, time)
	}

	// 开启自动保存ppt ，同时开启30分钟检查一次是否继续开启自动保存（30分钟内没有修改，关闭自动保存）
	const startAutoSavePpt = (time: number) => {
		logger.info('开启ppt自动保存')
		checkCloseAutoSaveOrNot(time)
		saveTimeoutId = autoSaveToCloud(time)
		saveTime = time
	}

	const closeAutoSavePpt = () => {
		logger.info('关闭ppt自动保存')
		saveTimeoutId && clearTimeout(saveTimeoutId)
		saveTimeoutId = undefined
		checkStartAutoSaveTimeId.value &&
			clearInterval(checkStartAutoSaveTimeId.value)
		checkStartAutoSaveTimeId.value = undefined
	}

	return {
		checkIsAutoSaving,
		checkAutoSaveAbort,
		startAutoSavePpt,
		closeAutoSavePpt
	}
}
