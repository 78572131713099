import { MutationTypes, useStore } from '@/store'
import useFullScreen from './useFullScreen'
import useQianKun from './useQianKun'
import { enterFullscreen, exitFullscreen } from '../utils/fullscreen'
import useRunTime from './useRunTime'
import { ScreenType } from '@/store/state'
import useGlobalPencil from './useGlobalPencil'
import useTeachingTabPage from './useTeachingTabPage'

export default () => {
    const store = useStore()
    const { startFullScreen, stopFullScreen } = useFullScreen()
    const { isMicroApp } = useQianKun()
    const { isPowerByTabPage, sendToHost } = useTeachingTabPage()
    const { runtime } = useRunTime()
    const { sendContainerSize } = useGlobalPencil()

    // 进入放映状态（从当前页开始）
    const enterScreening = (screenType?: ScreenType) => {
        const _screenType = screenType || runtime.playPptRule.play
        if (isMicroApp) {
            startFullScreen()
        } else if (isPowerByTabPage) {
            sendToHost('SHOW_WORKBENCH_FOOTER', false)
        } else {
            enterFullscreen()
        }
        store.commit(MutationTypes.SET_SCREENING, {
            screening: true,
            screenType: _screenType
        })
        // 设置画布大小
        sendContainerSize()
    }

    // 进入放映状态（从第一页开始）
    const enterScreeningFromStart = (screenType?: ScreenType) => {
        store.commit(MutationTypes.UPDATE_SLIDE_INDEX, 0)
        enterScreening(screenType)
    }

    // 退出放映状态
    const exitScreening = (screenType?: ScreenType) => {
        if (isMicroApp) {
            // 子应用
            // 通知主应用退出全屏
            stopFullScreen()
        } else if (isPowerByTabPage) {
            sendToHost('SHOW_WORKBENCH_FOOTER', true)
        } else {
            exitFullscreen()
        }
        const _screenType = screenType || runtime.playPptRule.stop
        const screening = _screenType !== 'edit-check'
        store.commit(MutationTypes.SET_SCREENING, {
            screening,
            screenType: _screenType
        })
    }

    return {
        enterScreening,
        enterScreeningFromStart,
        exitScreening
    }
}
