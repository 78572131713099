import { MutationTypes, useStore } from '@/store'
import { computed, createApp, h, reactive, ref, toRefs, watch } from 'vue'

type LoadingOptions = {
	visible: any
	text: any
	isTransition: any
	timeout?: number
	target: HTMLElement
	parent?: HTMLElement
	beforeClose?: () => boolean
	closed?: () => void
}
const createGlobalLoadingComponent = (options: LoadingOptions) => {
	let closeTimer: number
	let afterLeaveTimer: number
	const afterLeaveFlag = ref(false)

	const data = reactive(
		Object.assign({}, { isTransition: true, visible: false }, options)
	)

	const setText = (text: string) => {
		data.text = text
	}

	const destroySelf = () => {
		removeLoadingChild()
		loadingInstance.unmount()
	}

	const removeLoadingChild = () => {
		vm.$el?.parentNode
			? vm.$el?.parentNode?.removeChild(vm.$el)
			: vm.$el.remove()
	}

	const close = () => {
		if (options.beforeClose && !options.beforeClose()) return

		afterLeaveFlag.value = true
		clearTimeout(afterLeaveTimer)

		afterLeaveTimer = window.setTimeout(handleAfterLeave, 400)
		data.visible = false
		closeTimer && clearTimeout(closeTimer)
		options.closed?.()
	}

	const handleAfterLeave = () => {
		if (!afterLeaveFlag.value) return
		afterLeaveFlag.value = false
		destroySelf()
	}
	if (options.timeout) {
		closeTimer = window.setTimeout(close, options.timeout)
	}

	const loadingComponent = {
		name: 'GlobalLoading',
		setup() {
			return () => {
				const spinner = data.isTransition
					? [
							h(
								'span',
								{
									className: 'global-loading-span'
								},
								[h('i'), h('i'), h('i'), h('i')]
							)
					  ]
					: []

				return h(
					'div',
					{
						className: 'global-loading-container'
					},
					[
						...spinner,
						h('div', { className: 'global-loading-text-container' }, [
							data.text
						])
					]
				)
			}
		}
	}
	const loadingInstance = createApp(loadingComponent)
	const vm = loadingInstance.mount(document.createElement('div'))
	options.target.appendChild(vm.$el)
	return {
		...toRefs(data),
		setText,
		removeLoadingChild,
		close,
		handleAfterLeave,
		vm,
		get $el(): HTMLElement {
			return vm.$el
		}
	}
}
type LoadingInstance = ReturnType<typeof createGlobalLoadingComponent>

let store: any
let loadingInstance: LoadingInstance | null
export default () => {
	let loadingState: Promise<void> | null = null
	if (!store) {
		store = useStore()
	}

	const editingScreening = computed(() => store.state.editingScreening)
	const screenType = computed(() => store.state.screenType)

	/**
	 * 加载标识
	 */
	const loading = ref(false)
	watch(
		() => store.state.pageLoading,
		(newVal) => {
			loading.value = newVal
		}
	)
	const loadingText = ref('')
	watch(
		() => store.state.pageLoadingText,
		(newVal) => {
			loadingText.value = newVal
		}
	)

	/**
	 * 显示加载效果
	 * @param timeout  默认8000关闭
	 */
	const showLoading = (
		text = '加载中...',
		timeout = 8000,
		options?: LoadingOptions
	) => {
		loadingState = new Promise<void>((reslove) => {
			if (loadingInstance) {
				closeLoading()
			}
			text && store.commit(MutationTypes.PAGE_LOADING_TEXT, text)
			store.commit(MutationTypes.PAGE_LOADING, true)
			setTimeout(() => {
				loadingInstance = createGlobalLoadingComponent({
					visible: store.state.pageLoading,
					text: store.state.pageLoadingText,
					isTransition: screenType.value === 'edit-check',
					timeout,
					target: !editingScreening.value
						? document.body
						: document.getElementById('ppt')!,
					...options
				})
				reslove()
			}, 100)
		})
	}

	/**
	 * 关闭加载
	 */
	const closeLoading = () => {
		loadingState?.then(() => {
			if (loadingInstance) {
				loadingInstance.close()
				loadingInstance = null
			}
			store.commit(MutationTypes.PAGE_LOADING, false)
		})
		// setTimeout(() => {
		// 	if (loadingInstance) {
		// 		loadingInstance.close()
		// 		loadingInstance = null
		// 	}
		// 	store.commit(MutationTypes.PAGE_LOADING, false)
		// }, 100)
	}

	/**
	 * 更改loading文字
	 */
	const changeLoadingText = (loadingText: string) => {
		if (loadingInstance) {
			loadingInstance.setText(loadingText)
		}
		store.commit(MutationTypes.PAGE_LOADING_TEXT, loadingText)
	}

	const destroyedAll = () => {
		const loadingEls = document.querySelectorAll('.global-loading-container') || []
		loadingEls.forEach((el) => {
			el.remove()
		})
	}

	return {
		loading,
		loadingText,
		showLoading,
		closeLoading,
		changeLoadingText,
		destroyedAll
	}
}
