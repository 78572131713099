export type MediaTranscodeProgress = {
  currentFps: number
  currentKbps: number
  duration: number
  durationmark: string
  frames: number
  percent: number
  targetSize: number
  time: number
  timemark: string
  transCodeId: number
}

export default () => {
  const Electron = window.Electron
  const Electron_SocketIo = window.Electron_SocketIo

  const addEventListener = (
    eventName: string,
    handler: (...args: any[]) => void
  ) => {
    if (!Electron) {
      throw new Error('Electron is not defined')
    }
    Electron.addEventListener(eventName, handler)
  }

  const removeEventListener = (
    eventName: string,
    handler: (...args: any[]) => void
  ) => {
    if (!Electron) {
      throw new Error('Electron is not defined')
    }
    Electron.removeEventListener(eventName, handler)
  }

  const removeAllEventListener = (eventName: string) => {
    if (!Electron) {
      throw new Error('Electron is not defined')
    }
    Electron.removeEventAllListener(eventName)
  }

  const sendToHost = (channel: string, ...args: any[]) => {
    if (!Electron) {
      throw new Error('Electron is not defined')
    }
    Electron.sendToHost(channel, ...args)
  }
  // 媒体资源转码
  const mediaTranscoding = (
    path: string,
    options: any,
    successFunc: (info: any) => void,
    failFunc: (e: any) => void,
    transcodeListenerFunc: (res: MediaTranscodeProgress) => void,
    afterBeginMediaTranscoding?: (transcodeId: number) => void
  ) => {
    if (!Electron) {
      throw new Error('Electron is not defined')
      return
    }
    return new Promise<any>((resolve) => {
      const transcodeId = Electron.Utils.mediaTranscoding(
        path,
        options,
        (info: any) => {
          successFunc(info)
          resolve('')
        },
        (e: any) => {
          failFunc(e)
          resolve('')
          // reject(e)
        }
      )
      afterBeginMediaTranscoding && afterBeginMediaTranscoding(transcodeId)
      options.type !== 'ignore' &&
        addTranscodeListener(transcodeId, transcodeListenerFunc)
    })
  }
  // 删除正在进行的转码任务
  const killMediaTranscode = (transcodeId: number) => {
    Electron.Utils.mediaTranscodingKill(transcodeId)
  }

  // 注册监听任务进度
  const addTranscodeListener = (
    transcodeId: number,
    transcodeListenerFunc: (res: MediaTranscodeProgress) => void
  ) => {
    if (!Electron_SocketIo) {
      throw new Error('Electron_SocketIo is not defined')
      return
    }
    Electron_SocketIo.on(
      window.Electron.IpcWebContentChannel.Utils.ON_MEDIA_TRANSCODING_PROGRESS +
        '_' +
        transcodeId,
      transcodeListenerFunc
    )
  }

  const removeTranscodeListener = (transcodeId: number) => {
    if (!Electron_SocketIo) {
      throw new Error('Electron_SocketIo is not defined')
      return
    }
    window.Electron_SocketIo.off(
      window.Electron.IpcWebContentChannel.Utils.ON_MEDIA_TRANSCODING_PROGRESS +
        '_' +
        transcodeId
    )
  }

  const openFileDir = (
    filters: { name: string; extensions: string[] },
    handleMediaInfo: (path: string, info: any) => void
  ) => {
    return new Promise((resolve, reject) => {
      window.Electron.Utils.openDirDiaLog({
        filters: [filters],
        properties: ['openFile'],
      })
        .then((res: any) => {
          if (res?.[0]) {
            window.Electron.Utils.getMediaInfo(res[0]).then((info: any) => {
              handleMediaInfo(res[0], info)
              resolve('')
            })
          } else {
            resolve('')
          }
        })
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  const getSystemFonts = () => {
    return new Promise((resolve, reject) => {
      if (!window.Electron?.Utils?.getSystemFonts) {
        resolve([])
        return
      }
      window.Electron.Utils.getSystemFonts()
        .then((res: any) => {
          resolve(res)
        })
        .catch((err: any) => {
          reject(err)
        })
    })
  }

  return {
    getSystemFonts,
    openFileDir,
    addEventListener,
    removeEventListener,
    removeAllEventListener,
    sendToHost,
    mediaTranscoding,
    killMediaTranscode,
    addTranscodeListener,
    removeTranscodeListener,
  }
}
