import { ElfElementTypes, PPTElement, PPTElfElement } from '@/types/slides';
import logger from '@evideo/logger';
import useResourceCenter from './useResourceCenter';
// TODO: 如果populate失败咋办？

export default () => {
  const {
    getEnjoySongDetail,
    getSheetSongDetail,
    getMusicRhythmDetail,
    getQuestionsDetail,
  } = useResourceCenter();

  const populateElem = async (elem: PPTElement) => {
    if (elem.type === 'elf') {
      elem = elem as PPTElfElement;
      if ((elem as any)._meta !== undefined) {
        return elem;
      }
      // TODO: 如果存在meta,则拷贝meta填充_meta
      if ((elem as any).meta !== undefined) {
        elem['_meta'] = (elem as any).meta;
        return elem;
      }
      logger.time(`时间评估 ${elem.subtype} 接口请求`);
      if (elem.subtype === ElfElementTypes.SING_PLAY) {
        // TODO: 在这里根据elem的sid和dataFrom填充_meta
        const detail = await getSheetSongDetail(
          parseInt(elem['sid']),
          elem['source']
        );
        elem['_meta'] = detail?.data;
      } else if (elem.subtype === ElfElementTypes.ENJOY) {
        // TODO: 在这里根据elem的sid和dataFrom填充_meta
        const detail = await getEnjoySongDetail(
          parseInt(elem['sid']),
          elem['source']
        );
        elem['_meta'] = detail?.data;

        // 律动接口评估
      } else if (elem.subtype === ElfElementTypes.MUSIC_RHYTHM) {
        // TODO: 在这里根据elem的sid和dataFrom填充_meta
        const detail = await getMusicRhythmDetail(
          parseInt(elem['sid']),
          elem['source']
        );
        elem['_meta'] = detail?.data;
      } else if (elem.subtype === ElfElementTypes.QUESTIONS_BANK) {
        try {
          const detail = await getQuestionsDetail(
            parseInt(elem['sid']),
            elem['source']
          );
          elem['_meta'] = detail?.data;
        } catch (e) {
          console.error('获取题目详情信息失败', e);
        }
      }
      logger.timeEnd(`时间评估 ${elem.subtype} 接口请求`);
    }

    return elem;
  };
  return {
    populateElem,
  };
};
