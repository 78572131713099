import {
  Button,
  Carousel,
  Breadcrumb,
  Checkbox,
  Col,
  ConfigProvider,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Input,
  InputNumber,
  List,
  Menu,
  Modal,
  Pagination,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  Slider,
  Spin,
  Switch,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Empty,
  Tree
} from 'ant-design-vue'
import { App } from 'vue'
import infiniteScroll from 'vue3-infinite-scroll-better'

export default (app: App) => {
  app.component('InputNumber', InputNumber)
  app.component('Divider', Divider)
  app.component('Button', Button)
  app.component('ButtonGroup', Button.Group)
  app.component('Tooltip', Tooltip)
  app.component('Popover', Popover)
  app.component('Slider', Slider)
  app.component('Select', Select)
  app.component('SelectOption', Select.Option)
  app.component('SelectOptGroup', Select.OptGroup)
  app.component('Switch', Switch)
  app.component('Radio', Radio)
  app.component('RadioGroup', Radio.Group)
  app.component('RadioButton', Radio.Button)
  app.component('Input', Input)
  app.component('InputSearch', Input.Search)
  app.component('InputGroup', Input.Group)
  app.component('Modal', Modal)
  app.component('Dropdown', Dropdown)
  app.component('Menu', Menu)
  app.component('MenuItem', Menu.Item)
  app.component('Checkbox', Checkbox)
  app.component('Drawer', Drawer)
  app.component('Spin', Spin)
  app.component('ConfigProvider', ConfigProvider)
  app.component('Progress', Progress)
  app.component('Carousel', Carousel)
  app.component('Row', Row)
  app.component('Col', Col)
  app.component('Tabs', Tabs)
  app.component('TabsPane', Tabs.TabPane)
  app.component('Table', Table)
  app.component('Tag', Tag)
  app.component('Form', Form)
  app.component('FormItem', Form.Item)
  app.component('List', List)
  app.component('ListItem', List.Item)
  app.component('Pagination', Pagination)
  app.component('Empty', Empty)
  app.component('Tree', Tree)
  app.component('Breadcrumb', Breadcrumb)
  app.component('BreadcrumbItem', Breadcrumb.Item)
  app.use(infiniteScroll)
}
