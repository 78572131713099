import { MutationTypes, useStore } from '@/store'
import { PPTElement, Slide } from '@/types/slides'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import useAndroid from '@/hooks/useAndroid'
import useIpcRenderer from '@/hooks/useIpcRenderer'
import useShowMessage from './useShowMessage'
import useTeachingTabPage from './useTeachingTabPage'

export default () => {
	const store = useStore()
	const { showMeassage } = useShowMessage()
	const { sendToHost } = useTeachingTabPage()

	const { addHistorySnapshot } = useHistorySnapshot()
	const { checkJsBridge, openWebView: openAndroidWebView } = useAndroid()
	const {
		checkIpcRenderer,
		openWebView: openPCClientWebView
	} = useIpcRenderer()

	const checkLink = (linkUrl: string) => {
		const linkRegExp = /^(https?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?$/
		if (!linkUrl || !linkRegExp.test(linkUrl)) {
			showMeassage('不是正确的网页链接地址', 'error')
			return false
		}
		return true
	}

	const setLink = (handleElement: PPTElement, linkUrl: string) => {
		const linkRegExp = /^(https?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?$/
		if (!linkUrl || !linkRegExp.test(linkUrl)) {
			showMeassage('不是正确的网页链接地址', 'error')
			return false
		}
		const props = {
			link: {
				type: 'web-url',
				target: linkUrl
			}
		}
		store.commit(MutationTypes.UPDATE_ELEMENT, { id: handleElement.id, props })
		addHistorySnapshot()

		return true
	}

	const removeLink = (handleElement: PPTElement) => {
		store.commit(MutationTypes.REMOVE_ELEMENT_PROPS, {
			id: handleElement.id,
			propName: 'link'
		})
		addHistorySnapshot()
	}

	// 设置链接幻灯片的超链接
	const setLinkSlideId = (handleElement: PPTElement, linkSlideId: string) => {
		const props = {
			link: {
				type: 'slide',
				target: linkSlideId // slideIndex-slideId: slideIndex:存放第几页幻灯片 slideId存放幻灯片id
			}
		}
		store.commit(MutationTypes.UPDATE_ELEMENT, { id: handleElement.id, props })
		addHistorySnapshot()
		return true
	}

	// 重新设置幻灯片链接 ，在左侧幻灯片缩略图移动、剪切、删除时重置
	// 通过slideId找到幻灯片，未找到则为已删除幻灯片，元素应除去link属性；找到幻灯片，通过与幻灯片当前位置和slideIndex比较，不同则为幻灯片经过移动操作，因此重新设置元素的link属性
	const reSetLinkSlide = (newSlides: Slide[]) => {
		newSlides.forEach((slide: Slide) => {
			slide.elements.forEach((element: any) => {
				if (element?.link && element.link.type === 'slide') {
					const linkSlide = element.link.target.split('-')
					const linkSlideIndex = linkSlide?.[0]
					const linkSlideId = linkSlide?.[1]
					if (linkSlideIndex && linkSlideId) {
						let isExitSlide = false
						for (let i = 0; i < newSlides.length; i++) {
							if (newSlides[i].id === linkSlideId) {
								isExitSlide = true
								if (i !== linkSlideIndex) {
									const newLinkSlide = i + '-' + linkSlideId
									element.link.target = newLinkSlide
								}
								return
							}
						}
						if (!isExitSlide) {
							const _element = JSON.parse(JSON.stringify(element))
							store.commit(MutationTypes.REMOVE_ELEMENT_PROPS_IN_SLIDES, {
								id: _element.id,
								propName: 'link'
							})
						}
					}
				}
			})
		})
		return newSlides
	}

	/**
	 * 打开一个网页
	 * @param url
	 */
	const openWebLink = (url: string) => {
		if (checkJsBridge.value) {
			openAndroidWebView(url)
		} else if (checkIpcRenderer.value) {
			openPCClientWebView(url)
		} else if (window.Electron) {
			sendToHost('FLOAT_WEB_LINK', {
				url
			})
		} else {
			// sendToHost("FLOAT_WEB_LINK", {
			//   url,
			// });
			// window.open(
			// 	url,
			// 	'_blank',
			// 	// `height=200,
			// 	// 	width=320,   top=0,   left=24,   ．toolbar=no,   menubar=no,
			// 	//  scrollbars=no,   resizable=no,location=no,   status=no`
			// 	// 'toolbar=yes,scrollbars=yes,resizable=yes,modal=true,location=yes,alwaysRaised=yes'
			// 	'scrollbars=yes,resizable=yes,modal=true,alwaysRaised=yes'
			// )
			window.open(url)
		}
	}

	return {
		setLink,
		removeLink,
		setLinkSlideId,
		reSetLinkSlide,
		openWebLink,
		checkLink
	}
}
