import { Ref, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { MutationTypes, store } from '@/store'
import { generatePreview } from '@/utils/screenshot'
import logger from '@evideo/logger'
import { State } from '@/store/state'
import { Store } from 'vuex'
import {
    getImageBase64DataURL,
    videoSnapshotOssProcessSuffix
} from '@/utils/image'

const findAncestorWithClass = (element: HTMLElement, className: string) => {
    let ancestor = element.parentNode as any

    while (ancestor) {
        if (ancestor.classList.contains(className)) {
            return ancestor
        }
        ancestor = ancestor.parentNode
    }

    return null // 如果未找到符合条件的祖先元素，返回null
}

// 处理不能正确截图的元素
const handleNode = async (clone: Node) => {
    const div = clone as HTMLDivElement
    if (div.style.transform) {
        div.style.transform = 'none'
    }
    //  视频
    const videos =
        ((div.getElementsByTagName('video') as unknown) as Array<
            HTMLVideoElement
        >) || []
    for (let i = 0; i < videos.length; i++) {
        const video = videos[i]
        const videoContainer = findAncestorWithClass(video, 'video-js')
        if (!videoContainer) {
            const poster = video.poster
                ? video.poster
                : videoSnapshotOssProcessSuffix(video.src, 2000)
            const img = document.createElement('img')
            img.style.width = video.style.width
            img.style.height = video.style.height
            img.style.objectFit = 'contain'
            img.setAttribute('crossOrigin', 'Anonymous')
            img.src = await getImageBase64DataURL(
                poster,
                parseInt(img.style.width.replace('px', '')),
                parseInt(img.style.height.replace('px', ''))
            )
            video.replaceWith(img)
        }
    }
    // 表格 双击编辑 mask-tip
    const masktips =
        ((div.getElementsByClassName('mask-tip') as unknown) as Array<
            HTMLVideoElement
        >) || []
    masktips && masktips.forEach((mt) => mt.remove())

    // 范围边框
    const operates =
        ((div.getElementsByClassName('operate') as unknown) as Array<
            HTMLVideoElement
        >) || []
    operates && operates.forEach((op) => op.remove())
    // 多选边框
    const multi_operates =
        ((div.getElementsByClassName(
            'multi-select-operate'
        ) as unknown) as Array<HTMLVideoElement>) || []
    multi_operates && multi_operates.forEach((op) => op.remove())
    // 部分范围边框删除不了 改成隐藏
    const operates_again =
        ((div.getElementsByClassName('operate') as unknown) as Array<
            HTMLVideoElement
        >) || []
    operates_again &&
        operates_again.forEach((op) => (op.style.display = 'none'))
    // 元素自定义路径动画
    const animationCustomPath =
        ((div.getElementsByClassName(
            'animation-custom-path'
        ) as unknown) as Array<HTMLVideoElement>) || []
    animationCustomPath &&
        animationCustomPath.forEach((op) => (op.style.display = 'none'))

    // 微应用 移除noscript
    const noscripts = div.querySelectorAll('noscript') || []
    noscripts && noscripts.forEach((noscript) => noscript.remove())

    // 移除弹窗圆圈
    const spinBlurs = div.querySelectorAll('ant-spin-blur') || []
    spinBlurs &&
        spinBlurs.forEach((spin) => spin.classList.remove('ant-spin-blur'))
    return div
}

export const generateSlidePreviewStates = ref<Record<string, number>>({})

/**
 * 根据元素进行截图
 * @param element
 * @param options
 * @returns
 */
export const generateElementPreview = (
    element: HTMLElement,
    options: any = {}
) => {
    if (!element) {
        return Promise.reject('Can not generate slide preview')
    }
    return generatePreview(element, {
        width: element.clientWidth,
        height: element.clientHeight,
        quality: 0.5,
        onclone: handleNode,
        ...(options || {})
    })
}
const delay = (ms: number) => {
    return new Promise(function(resolve) {
        setTimeout(function() {
            resolve(true)
        }, ms)
    })
}

let slideWrapper: any | Ref<Element> = null
let previewElement: HTMLDivElement | null = null
const isPptGeneratePreview = false // 当前是否是ppt导入的生成缩略图
const pptGeneratePreviewDelayTime = 150

export const isImportPptGeneratePreview = ref(false)

/**
 * @description: 针对中间画布区域进行截图
 * @param {Ref} viewportWrapper
 * @return {*}
 */
export default (viewportWrapper?: Ref<any> | undefined) => {
    viewportWrapper && (slideWrapper = viewportWrapper)
    /**
     * @description:以 wrapper 为准去做截图
     * @return {*}
     */
    const generateSlidePreview = () => {
        // ppt导入后第一次遍历幻灯片会触发此方法
        if (isPptGeneratePreview) {
            return
        }
        const screenType = store.state.screenType
        if (screenType !== 'edit-check') {
            logger.warn('screenType is not edit-check,can not generate preview')
            return
        }
        if (!slideWrapper || !slideWrapper.value) {
            logger.warn('slideWrapper is null,can not generate slide preview')
            return
        }
        // fixme:直接引用了store.state ，没有使用useStore，因为不会在组件内部调用
        const slideIndex = store.state.slideIndex
        const currentSlide = store.state.slides[slideIndex]
        previewElement = slideWrapper.value.cloneNode(true)
        const id = `slide-preview-${uuidv4()}`
        previewElement!.id = id
        previewElement!.style.left = '-9999px'
        previewElement!.style.top = '-9999px'
        slideWrapper.value.parentElement.appendChild(previewElement)
        logger.time(`generateSlidePreview-${id}`)
        generatePreview(previewElement!, {
            width: slideWrapper.value.clientWidth,
            height: slideWrapper.value.clientHeight,
            quality: 0.5,
            onclone: handleNode
        }).then((previewImageUrl) => {
            if (slideWrapper.value) {
                slideWrapper.value.parentElement.removeChild(
                    document.getElementById(id)
                )
                previewElement?.remove()
                previewElement = null
                store.commit(MutationTypes.UPDATE_SLIDE_PREVIEW, {
                    slideIndex,
                    slideId: currentSlide.id,
                    url: previewImageUrl
                })
                logger.timeEnd(`generateSlidePreview-${id}`)
            }
        })
    }

    /**
     * @description:遍历 slide， 以 wrapper 为准去做截图
     * @param store  vuex
     * @param isFilter  是否只生成没有预览图的图
     */
    const pptGeneratePreviewOneByOne = async (
        store: Store<State>,
        options: {
            isFilter: boolean
            process?: any
        } = {
            isFilter: false,
            process: undefined
        }
    ) => {
        // isPptGeneratePreview = true
        const noPreviewSlides = store.state.slides
            .map((slide, index) => {
                if (options.isFilter && slide.preview) {
                    return
                }
                return {
                    slide,
                    index
                }
            })
            .filter(Boolean)
        if (noPreviewSlides.length <= 0) {
            return
        }
        store.commit(
            MutationTypes.UPDATE_SLIDE_INDEX,
            noPreviewSlides[0]?.index
        )
        await delay(500) // * 初次截图要等待久点
        const screenType = store.state.screenType
        isImportPptGeneratePreview.value = true
        for (let i = 0; i < noPreviewSlides.length; i++) {
            options.process && options.process(i, noPreviewSlides.length)
            const obj = noPreviewSlides[i]
            let _slideWrapper: HTMLElement | null = null
            let _previewElement: HTMLElement | null = null
            if (screenType === 'edit-check') {
                _slideWrapper = document.querySelector('.editor-slide-warpper')
                if (_slideWrapper) {
                    _previewElement = _slideWrapper.cloneNode(
                        true
                    ) as HTMLElement
                }
            } else {
                const id = `.screen-slide-warpper-${obj?.slide.id}`
                _slideWrapper = document
                    .querySelector(id)
                    ?.querySelector('.slide-content') as HTMLElement
                if (_slideWrapper) {
                    _previewElement = _slideWrapper.cloneNode(
                        true
                    ) as HTMLDivElement
                    _previewElement!.style.left = '0px'
                    _previewElement!.style.top = '0px'
                    _previewElement!.style.transform = 'none'
                }
            }
            let parentElement: HTMLElement | null = null
            if (_slideWrapper) {
                parentElement = _slideWrapper.parentElement
            }

            if (!_previewElement || !_slideWrapper || !parentElement) {
                continue
            }
            const id = `slide-preview-${uuidv4()}-${i}`
            _previewElement!.id = id
            _previewElement!.style.left = '-9999px'
            _previewElement!.style.top = '-9999px'
            parentElement!.appendChild(_previewElement as Node)

            // 切换下一个幻灯片时，延迟150ms确保下一个幻灯片已渲染在画布中
            store.commit(
                MutationTypes.UPDATE_SLIDE_INDEX,
                noPreviewSlides[i + 1]?.index
            )
            await delay(pptGeneratePreviewDelayTime)

            let previewImageUrl = ''
            try {
                logger.time('pptGeneratePreview')
                previewImageUrl = (await generatePreview(_previewElement, {
                    width: _previewElement!.clientWidth,
                    height: _previewElement!.clientHeight,
                    quality: 0.5,
                    onclone: handleNode
                })) as string
            } catch (e) {
                logger.error(
                    `ppt generate preview error, slide id : ${obj?.slide?.id}; `,
                    e
                )
                continue
            } finally {
                parentElement!.removeChild(document.getElementById(id)!)
                _previewElement?.remove()
                _slideWrapper = null
                parentElement = null
                _previewElement = null
                logger.timeEnd('pptGeneratePreview')
            }

            store.commit(MutationTypes.UPDATE_SLIDE_PREVIEW, {
                slideIndex: obj?.index,
                url: previewImageUrl
            })
        }
        store.commit(MutationTypes.UPDATE_SLIDE_INDEX, 0)
        isImportPptGeneratePreview.value = false
    }

    return {
        generateSlidePreview,
        pptGeneratePreviewOneByOne,
        isImportPptGeneratePreview
    }
}
