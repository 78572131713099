import { asciiToUtf8 } from '@/utils/common'
import contentDisposition from 'content-disposition'

export default () => {
	const getCDNUrlFromLocalFile = (localFile: string) => {
		return `http://127.0.0.1:8092/${localFile}`
	}

	const getFileNameFromCDNUrl = async (cdnUrl: string) => {
		if (!cdnUrl) {
			return ''
		}

		const index = cdnUrl.lastIndexOf('/')
		const filename = index > 0 ? cdnUrl.substr(index + 1) : cdnUrl

		const res = await fetch(cdnUrl, {
			method: 'HEAD'
		})
		const disposition = res.headers.get('content-disposition')
		if (!disposition) {
			return filename
		}

		const ossFilename = contentDisposition.parse(disposition).parameters
			.filename
		if (ossFilename) {
            if (window.__ELECTRON__) {
                // electron中，filename以ascii格式返回
				return asciiToUtf8(ossFilename)
			}
			return decodeURIComponent(ossFilename)
		}
		return filename
	}

	return {
		getCDNUrlFromLocalFile,
		getFileNameFromCDNUrl
	}
}
