/*
 * @description: 作为子应用时，通知主应用应用全屏操作
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-08-24 17:48:15
 * @lastEditors: xuhuale
 * @lastEditTime: 2023-02-17 16:04:47
 */
import useQianKun from './useQianKun'
import useQianKunEventCenter from './useQianKunEventCenter'
import {
	isFullscreen,
	enterFullscreen,
	exitFullscreen
} from '@/utils/fullscreen'
import logger from '@evideo/logger'
export default () => {
	const { isMicroApp, document } = useQianKun()
	const { noticeFullScreenStatus } = useQianKunEventCenter()

	/**
	 * 开始全屏
	 */
	const startFullScreen = () => {
		if (isMicroApp) {
			noticeFullScreenStatus(true).then((res: any) => {
				logger.info('start full screen:', res)
			})
		}
	}

	/**
	 * 结束全屏
	 */
	const stopFullScreen = () => {
		if (isMicroApp) {
			noticeFullScreenStatus(false).then((res: any) => {
				logger.info('stop full screen:', res)
			})
		}
	}

	const switchFullScreen = () => {
		if (isFullscreen()) {
			exitFullscreen()
		} else {
			enterFullscreen(
				(document as any).documentElement
					? (document as any).documentElement
					: document
			)
		}
	}

	return {
		startFullScreen,
		stopFullScreen,
		switchFullScreen
	}
}
