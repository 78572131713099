import logger from '@evideo/logger'
import { computed } from 'vue'

declare global {
	interface Window {
		require?: any
	}
}
let ipcRenderer: any

if (window.require) {
	ipcRenderer = window.require('electron').ipcRenderer
}

const ipcRendererSend = (channel: string, ...args: any[]) => {
	logger.info(`ipcRenderer.send 被调用;key:${channel}`, args)
	ipcRenderer.send(channel, args)
}

// const ipcRendererSendSync = (channel: string, ...args: any[]) => {
// 	logger.log(`ipcRenderer.sendSync 被调用;key:${channel}`, args)
// 	ipcRenderer.sendSync(channel, args)
// }
export default () => {
	const checkIpcRenderer = computed(() => !!ipcRenderer)
	const openWebView = (url: string) => {
		return new Promise((resolve) => {
			if (ipcRenderer) {
				ipcRendererSend('openWebView', url)
				resolve(true)
			} else {
				resolve(false)
			}
		})
	}
	// 与electron进程通信
	const ipcRenderSendToHost = (channel: string, ...args: any[]) => {
		logger.info(`ipcRenderer.sendToHost 被调用;key:${channel}`, args)
		try {
			ipcRenderer.sendToHost(channel, args)
		} catch (err) {
			logger.info(`${channel}发送失败`, err)
		}
	}
	const onBrowserWindowMaximizeFullscreenListener = (callBack: any) => {
		if (ipcRenderer) {
			ipcRenderer.on(
				'browserWindow-maximize-fullscreen',
				(event: any, message: any) => {
					callBack(event, message)
				}
			)
		}
	}

	return {
		checkIpcRenderer,
		openWebView,
		ipcRenderSendToHost,
		onBrowserWindowMaximizeFullscreenListener
	}
}
