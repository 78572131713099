import useQianKun from '@/hooks/useQianKun'
import { useStore } from '@/store'
import emitter, { EmitterEvents } from '@/utils/emitter'
import logger from '@evideo/logger'
import useTeachingTabPage from './useTeachingTabPage'

export default () => {
	const { eventCenter, event } = useQianKun()
	const { sendToHost, receiveHostMessage } = useTeachingTabPage()
	const store = useStore()

	/**
	 * 打开更多资源弹窗
	 * @returns
	 */
	const openMoreResourceDialog = () => {
		return new Promise<void>((resolve) => {
			eventCenter.send('openMoreDailog', {
				slideIndex: store.state.slideIndex
			})
			resolve()
		})
	}

	/**
	 * 打开谱例歌曲弹窗
	 * @returns
	 */
	const openSingPlayDialog = () => {
		return new Promise<void>((resolve) => {
			eventCenter.send(
				event.RESOURCE_OPEN_SONG_MODAL,
				{ type: 'sheet' },
				(data: any) => {
					resolve(data)
				}
			)
		})
	}

	/**
	 * 打开欣赏歌曲弹窗
	 * @returns
	 */
	const openEnjoySongDialog = () => {
		return new Promise((resolve) => {
			eventCenter.send(
				event.RESOURCE_OPEN_SONG_MODAL,
				{ type: 'enjoy' },
				(data: any) => {
					resolve(data)
				}
			)
		})
	}

	/**
	 * 打开关联教案/说课的弹窗
	 * @param type
	 * @param extData	coursewareId:'课件id',			coursewareSource:'课件来源'
	 */
	const openLessonOrSpeakModal = (type: 'lesson' | 'speak', extData = {}) => {
		return new Promise((resolve) => {
			eventCenter.send(
				'openLessonOrSpeakModal',
				{
					type,
					...extData
				},
				(data: any) => {
					resolve(data)
				}
			)
		})
	}

	/**
	 * 取消关联教案/说课
	 * @param type
	 * @param relate_courseware_ids 除了要取消的id以外的ids
	 * @param extData coursewareId:'课件id',			coursewareSource:'课件来源'
	 * @returns
	 */
	const cancelLessonOrSpeakRelate = (
		type: 'lesson' | 'speak',
		relate_courseware_ids: any[],
		extData = {}
	) => {
		return new Promise((resolve) => {
			eventCenter.send(
				'cancelLessonOrSpeakRelate',
				{
					type,
					relate_courseware_ids,
					...extData
				},
				(data: any) => {
					resolve(data)
				}
			)
		})
	}
	/*
	 * 打开音乐律动弹窗
	 * @returns
	 */
	const openMusicRhythmDialog = () => {
		return new Promise((resolve) => {
			eventCenter.send(
				event.RESOURCE_OPEN_SONG_MODAL,
				{ type: 'rhythm' },
				(data: any) => {
					resolve(data)
				}
			)
		})
	}

		/**
	 * 打开题目组弹窗
	 * @returns
	 */
		const openQGroupDialog = () => {
			return new Promise<void>((resolve) => {
				eventCenter.send(
					event.RESOURCE_OPEN_SONG_MODAL,
					{ type: 'qGroup' },
					(data: any) => {
						resolve(data)
					}
				)
			})
		}

	/**
	 * 获取新建的PPT关联的教案/说课
	 * @param type
	 * @returns
	 */
	const getNewPptDetails = (type: 'lesson' | 'speak') => {
		return new Promise((resolve) => {
			eventCenter.send(
				'getNewPptDetails',
				{
					type
				},
				(data: any) => {
					resolve(data)
				}
			)
		})
	}
	/**
	 * 打开题库弹窗
	 * @returns
	 */
	const openQuestionsBankDialog = () => {
		return new Promise((resolve) => {
			eventCenter.send(
				event.RESOURCE_OPEN_SONG_MODAL,
				{ type: 'questions' },
				(data: any) => {
					resolve(data)
				}
			)
		})
	}

	/**
	 * 关闭或显示loading
	 * @param parms
	 * @returns
	 */
	const showOrCloseLoading = (parms: {
		loading: boolean
		loadingText?: string
		timeout?: number
	}) => {
		return new Promise((resolve) => {
			eventCenter.send(event.COMMON_SHOW_LOADING, parms, (data: any) => {
				resolve(data)
			})
		})
	}

	/**
	 * 消息提示
	 * @param data
	 * @returns
	 */
	const notifyMessage = (parms: {
		type: 'success' | 'error' | 'warning' | 'info' | 'warning'
		message: string
		element?: Element
	}) => {
		return new Promise((resolve) => {
			eventCenter.send(event.COMMON_NOTIFY_MESSAGE, parms, (data: any) => {
				resolve(data)
			})
		})
	}

	/**
	 * 资源中心更新资源
	 * @param parmssrc/hooks/useFullScreen.ts
	 */
	const updateResource = (parms: any = {}) => {
		return new Promise((resolve) => {
			/**
			 * 预览课件模式时，针对“event.RESOURCE_UPDATE_RESOURCE”可能没有做任何操作，导致回调函数没有被触发，影响ppt保存
			 * 因而增加5s中的超时处理，若消息没有被处理，则5s后resolve*/
			const timeoutId = setTimeout(() => {
				logger.error(
					`updateResource ${event.RESOURCE_UPDATE_RESOURCE} timeout 50000ms`
				)
				resolve('')
			}, 5000)
			eventCenter.send(event.RESOURCE_UPDATE_RESOURCE, parms, (data: any) => {
				clearTimeout(timeoutId)
				resolve(data)
			})
		})
	}

	/**
	 * 通知全屏状态
	 * @param fullScreen
	 * @returns
	 */
	const noticeFullScreenStatus = (fullScreen: boolean) => {
		return new Promise((resolve) => {
			eventCenter.send(
				event.COMMON_FULL_SCREEN,
				{ fullScreen },
				(data: any) => {
					resolve(data)
				}
			)
		})
	}

	const noticePptStopPlay = () => {
		return new Promise((resolve) => {
			eventCenter.send('PPT_STOP_PLAY', {}, (data: any) => {
				resolve(data)
			})
		})
	}

	const noticeLoadPreviousOrNextPPt = (type: 'next' | 'previous') => {
		return new Promise((resolve) => {
			eventCenter.send('PPT_DOUBLE_CLICK_PAGE_BTN', { type }, (data: any) => {
				resolve(data)
			})
		})
	}

	/**
	 * 编辑状态下返回
	 * @returns
	 */
	const noticeGoBack = () => {
		return new Promise((resolve) => {
			eventCenter.send('PPT_GO_BACK', {}, (data: any) => {
				resolve(data)
			})
		})
	}

	/**
	 * 数据上报
	 * @param reportData
	 * @returns
	 */
	const statReport = (reportData: {
		moduleid: string
		pageid: string
		eventid: string
		eventattr: Record<string, unknown>
	}) => {
		return new Promise(() => {
			eventCenter.send &&
				eventCenter.send('STAT_REPORT', {
					moduleid: reportData.moduleid,
					pageid: reportData.pageid,
					eventid: reportData.eventid,
					eventattr: reportData.eventattr || {}
				})
		})
	}

	/**
	 * 注册外部通知保存ppt
	 * @returns
	 */
	const registerSavePpt = (callBack: any) => {
		eventCenter.sub(event.PPTIST_SAVE_PPT, (data: any, event: any) => {
			callBack && callBack({ data, event })
		})
	}

	/**
	 * 注册外部通知加载ppt
	 * @returns
	 */
	const registerLoadPPt = (callBack: any) => {
		eventCenter.sub(event.PPTIST_LOAD_PPT, (data: any, event: any) => {
			callBack && callBack({ data, event })
		})
	}

	/**
	 * 注册外部通知重置ppt
	 * @returns
	 */
	const registerResetPPt = (callBack: any) => {
		eventCenter.sub('PPTIST_RESET_PPT', () => {
			callBack && callBack()
		})
	}

	/**
	 * 注册外部通知加载知识点
	 * @param callBack
	 */
	const registerLoadKnowledgePoints = (callBack: any) => {
		eventCenter.sub(
			event.PPTIST_LOAD_KNOWLEDGE_POINT,
			(data: any, event: any) => {
				callBack && callBack({ data, event })
			}
		)
	}

	/**
	 * 注册外部通知ppt的状态变化
	 * @param callBack
	 */
	const registerPptIsModified = (callBack: any) => {
		eventCenter.sub(event.PPTIST_IS_MODIFIED, (data: any, event: any) => {
			callBack && callBack({ data, event })
		})
	}
	/**
	 * 注册外部获取ppt状态事件
	 * @param callBack
	 */
	const registerGetPptStatus = (callBack: any) => {
		eventCenter.sub('PPT_GET_STATUS', (data: any, event: any) => {
			callBack && callBack({ data, event })
		})
	}

	/**
	 * 发送打开学生弹窗事件
	 */
	const registerStudentsModal = () => {
		return new Promise<any[]>((resolve) => {
			if (eventCenter && eventCenter?.send) {
				eventCenter.send('ppt-open-students-modal')
				eventCenter.sub('ppt-selected-students', (students: any[]) => {
					resolve(students)
				})
				eventCenter.sub('ppt-select-student-modal-close', () => {
					resolve([])
				})
			} else {
				logger.info('——当前在多标签项目——')
				const selectStudentTimestamp = Date.now()
				sendToHost('show-select-student-popper', {
					disableRandom: false,
					allowSelectEmpty: true,
					timeStamp: selectStudentTimestamp
				})
				receiveHostMessage(
					'select-student-popper-selected',
					(
						e,
						data: {
							students: Array<{ id: string; name: string }>
							timeStamp?: number
						}
					) => {
						if (data?.timeStamp === selectStudentTimestamp) {
							resolve(data.students)
						} else {
							logger.info(
								'[ppt] the selected students message is not current tab'
							)
						}
					}
				)
			}
		})
	}
	/**
	 * 游戏pk发送打开学生弹窗事件
	 */
	const sendOpenStudentsModal = (
		selectData: any,
		// eslint-disable-next-line @typescript-eslint/ban-types
		receiveMessageToIframe: Function
	) => {
		const timeStamp = selectData?.timeStamp || -1
		sendToHost('show-select-student-popper', {
			...(selectData || {}),
			limitNum: selectData?.num,
			disableClose: selectData?.disableClose,
			defaultSelected: selectData?.defaultStudentIds,
			timeStamp
		})
		receiveHostMessage(
			'select-student-popper-selected',
			(
				e,
				data: {
					students: Array<{ id: string; name: string }>
					timeStamp?: number
					errorType?: string
				}
			) => {
				if (data?.timeStamp === timeStamp) {
					receiveMessageToIframe(data)
				} else {
					logger.info('[ppt] the selected students message is not current tab')
				}
			}
		)
	}
	/**
	 * 监听PPT 隐藏事件
	 */
	const registerPptHide = () => {
		return new Promise<void>((resolve) => {
			eventCenter.sub(EmitterEvents.ON_PPT_HIDE, () => {
				console.log('listen PPT HIDE')
				emitter.emit(EmitterEvents.ON_PPT_HIDE)
				resolve()
			})
		})
	}
	/**
	 * 初始化学生弹窗项目
	 */
	const initStudentsModal = () => {
		return new Promise(() => {
			eventCenter.send && eventCenter.send('ppt-init-students-modal')
		})
	}

	/**
	 * 关闭学生弹窗事件
	 */
	const closeStudentsModal = () => {
		return new Promise(() => {
			eventCenter.send && eventCenter.send('select-student-close')
		})
	}

	return {
		openMoreResourceDialog,
		registerPptHide,
		registerStudentsModal,
		initStudentsModal,
		closeStudentsModal,
		openSingPlayDialog,
		openEnjoySongDialog,
		openLessonOrSpeakModal,
		openQGroupDialog,
		cancelLessonOrSpeakRelate,
		openMusicRhythmDialog,
		showOrCloseLoading,
		notifyMessage,
		updateResource,
		noticeFullScreenStatus,
		noticePptStopPlay,
		noticeLoadPreviousOrNextPPt,
		noticeGoBack,
		registerSavePpt,
		registerLoadPPt,
		registerResetPPt,
		registerLoadKnowledgePoints,
		registerPptIsModified,
		registerGetPptStatus,
		getNewPptDetails,
		openQuestionsBankDialog,
		statReport,
		sendOpenStudentsModal
	}
}
