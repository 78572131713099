import { computed } from 'vue';
import _ from 'lodash';
import { MutationTypes, useStore } from '@/store';
import { createRandomCode } from '@/utils/common';
import { getImageSize } from '@/utils/image';
import { VIEWPORT_SIZE } from '@/configs/canvas';
import {
  ChartType,
  ElementTypes,
  ElfElementTypes,
  PPTElement,
  PPTElementLink,
  PPTElfElement,
  PPTLineElement,
  PPTShapeElement,
  TableCell,
  TableCellStyle,
} from '@/types/slides';
import { SHAPE_PATH_FORMULAS, ShapePoolItem } from '@/configs/shapes';
import { LinePoolItem } from '@/configs/lines';
import useHistorySnapshot from '@/hooks/useHistorySnapshot';
import {
  elfElementChecker,
  findElfByType,
} from '@/views/components/element/ElfElement/register';
import useShowMessage from './useShowMessage';
import useStatReport, { STAT_EVENT } from '@/hooks/useStatReport';

interface CommonElementPosition {
  top: number;
  left: number;
  width: number;
  height: number;
}

interface LineElementPosition {
  top: number;
  left: number;
  start: [number, number];
  end: [number, number];
}

export default () => {
  const store = useStore();
  const themeColor = computed(() => store.state.theme.themeColor);
  const fontColor = computed(() => store.state.theme.fontColor);
  const fontName = computed(() => store.state.theme.fontName);
  // const fontSize = computed(() => store.state.theme.fontSize)
  const viewportRatio = computed(() => store.state.viewportRatio);
  const creatingElement = computed(() => store.state.creatingElement);

  const { addHistorySnapshot } = useHistorySnapshot();

  const { pptEditStatReport } = useStatReport();

  // 创建（插入）一个元素并将其设置为被选中元素
  const createElement = (
    element: PPTElement | PPTElement[],
    callback?: () => void
  ) => {
    const $element = Array.isArray(element) ? element : [element];
    store.commit(MutationTypes.ADD_ELEMENT, $element);
    store.commit(MutationTypes.SET_ACTIVE_ELEMENT_ID_LIST, [
      $element[$element.length - 1].id,
    ]);

    // 1
    if (creatingElement.value) {
      store.commit(MutationTypes.SET_CREATING_ELEMENT, null);
    }

    setTimeout(() => {
      store.commit(MutationTypes.SET_EDITORAREA_FOCUS, true);
    }, 0);
    if (callback) callback();
    addHistorySnapshot();
    $element.forEach((element) => {
      pptEditStatReport(STAT_EVENT.PPT_EDIT_ELEMENT_CREATE, {
        element_id: element.id,
        element_type: element.type,
        subtype: (element as any).subtype,
        sid: (element as any).sid,
      });
    });
  };

  const createElfElement = (
    type: ElfElementTypes | string,
    extra?: any | any[]
  ) => {
    const config = _.cloneDeep(findElfByType(type));
    if (config) {
      if (config.initData.width <= 0) {
        config.initData.width = VIEWPORT_SIZE;
      }
      if (config.initData.height <= 0) {
        config.initData.height = VIEWPORT_SIZE * viewportRatio.value;
      }
      let $extra = extra || {};
      $extra = Array.isArray($extra) ? $extra : [$extra];
      const elements = $extra.map((extra: any) => {
        return {
          type: ElementTypes.ELF,
          id: createRandomCode(),
          left: (VIEWPORT_SIZE - config.initData.width) / 2,
          top:
            (VIEWPORT_SIZE * viewportRatio.value - config.initData.height) / 2,
          ...config.initData,
          ...extra,
        };
      });

      const { showMeassage } = useShowMessage();
      const res = elements.every((elem: any) => {
        const checker = elfElementChecker[elem!.subtype];
        if (checker && !checker.check()) {
          showMeassage(checker.msg);
          return false;
        }
        return true;
      });
      if (!res) {
        return;
      }
      createElement(elements as PPTElfElement[]);
    }
  };

  /**
   * 创建图片元素
   * @param src 图片地址
   */
  const createImageElement = (
    src: string,
    size?: number,
    md5?: string,
    extra?: any
  ) => {
    getImageSize(src).then(({ width, height }) => {
      const scale = height / width;

      if (scale < viewportRatio.value && width > VIEWPORT_SIZE) {
        width = VIEWPORT_SIZE;
        height = width * scale;
      } else if (height > VIEWPORT_SIZE * viewportRatio.value) {
        height = VIEWPORT_SIZE * viewportRatio.value;
        width = height / scale;
      }

      createElement({
        type: 'image',
        id: createRandomCode(),
        src,
        size,
        md5,
        width,
        height,
        left: (VIEWPORT_SIZE - width) / 2,
        top: (VIEWPORT_SIZE * viewportRatio.value - height) / 2,
        fixedRatio: true,
        rotate: 0,
        ...extra,
      });
    });
  };

  const createImageElements = (
    options:
      | {
          src: string;
          size?: number;
          md5?: string;
          extra?: any;
        }
      | {
          src: string;
          size?: number;
          md5?: string;
          extra?: any;
        }[]
  ) => {
    options = Array.isArray(options) ? options : [options];

    const promises = options.map((option) => {
      const { src, extra = {} } = option;
      const { width, height } = extra;
      if (width && height) {
        return new Promise((resolve) => {
          resolve({
            ...option,
            extra: {
              ...extra,
              width,
              height,
            },
          });
        });
      }
      return getImageSize(src).then(({ width, height }) => {
        return {
          ...option,
          extra: {
            ...extra,
            width,
            height,
          },
        };
      });
    });

    Promise.all(promises).then((options) => {
      const elements = options.map((option: any) => {
        // eslint-disable-next-line prefer-const
        const { src, size, md5, extra = {} } = option;
        let { height, width } = extra;
        const scale = height / width;
        if (scale < viewportRatio.value && width > VIEWPORT_SIZE) {
          width = VIEWPORT_SIZE;
          height = width * scale;
        } else if (height > VIEWPORT_SIZE * viewportRatio.value) {
          height = VIEWPORT_SIZE * viewportRatio.value;
          width = height / scale;
        }
        return {
          type: 'image',
          id: createRandomCode(),
          src,
          size,
          md5,
          left: (VIEWPORT_SIZE - width) / 2,
          top: (VIEWPORT_SIZE * viewportRatio.value - height) / 2,
          fixedRatio: true,
          rotate: 0,
          ...extra,
          width,
          height,
        };
      });
      createElement(elements);
    });
  };

  /**
   * 创建图表元素
   * @param chartType 图表类型
   */
  const createChartElement = (chartType: ChartType) => {
    createElement({
      type: 'chart',
      id: createRandomCode(),
      chartType,
      left: 300,
      top: 81.25,
      width: 400,
      height: 400,
      themeColor: themeColor.value,
      gridColor: fontColor.value,
      data: {
        labels: ['类别1', '类别2', '类别3', '类别4', '类别5'],
        series: [[12, 19, 5, 2, 18]],
      },
    });
  };

  /**
   * 创建表格元素
   * @param row 行数
   * @param col 列数
   */
  const createTableElement = (row: number, col: number) => {
    const style: TableCellStyle = {
      fontname: fontName.value,
      color: fontColor.value,
    };
    const data: TableCell[][] = [];
    for (let i = 0; i < row; i++) {
      const rowCells: TableCell[] = [];
      for (let j = 0; j < col; j++) {
        rowCells.push({
          id: createRandomCode(),
          colspan: 1,
          rowspan: 1,
          text: '',
          style,
        });
      }
      data.push(rowCells);
    }

    const DEFAULT_CELL_WIDTH = 100;
    const DEFAULT_CELL_HEIGHT = 36;

    const colWidths: number[] = new Array(col).fill(1 / col);

    const width = col * DEFAULT_CELL_WIDTH;
    const height = row * DEFAULT_CELL_HEIGHT;

    createElement({
      type: 'table',
      id: createRandomCode(),
      width,
      height,
      colWidths,
      data,
      left: (VIEWPORT_SIZE - width) / 2,
      top: (VIEWPORT_SIZE * viewportRatio.value - height) / 2,
      outline: {
        width: 2,
        style: 'solid',
        color: '#eeece1',
      },
      theme: {
        color: themeColor.value,
        rowHeader: true,
        rowFooter: false,
        colHeader: false,
        colFooter: false,
      },
    });
  };

  interface CreateTextData {
    content?: string;
    vertical?: boolean;
  }

  /**
   * 创建文本元素
   * @param position 位置大小信息
   * @param content 文本内容
   */
  const createTextElement = (
    position: CommonElementPosition,
    data?: CreateTextData
  ) => {
    const { left, top, width, height } = position;
    const content = data?.content || '';
    const vertical = data?.vertical || false;
    const id = createRandomCode();
    createElement(
      {
        type: 'text',
        id,
        left,
        top,
        width,
        height,
        content,
        rotate: 0,
        lineHeight: 1.5,
        defaultFontName: fontName.value,
        defaultColor: fontColor.value,
        vertical,
      },
      () => {
        setTimeout(() => {
          const editorRef: HTMLElement | null = document.querySelector(
            `#editable-element-${id} .ProseMirror`
          );
          if (editorRef) editorRef.focus();
        }, 10);
      }
    );
  };

  /**
   * 创建形状元素
   * @param position 位置大小信息
   * @param data 形状路径信息
   */
  const createShapeElement = (
    position: CommonElementPosition,
    data: ShapePoolItem
  ) => {
    const { left, top, width, height } = position;
    const newElement: PPTShapeElement = {
      type: 'shape',
      id: createRandomCode(),
      left,
      top,
      width,
      height,
      viewBox: data.viewBox,
      path: data.path,
      fill: themeColor.value,
      fixedRatio: false,
      rotate: 0,
    };
    if (data.special) newElement.special = true;
    if (data.pathFormula) {
      newElement.pathFormula = data.pathFormula;
      newElement.viewBox = [width, height];

      const pathFormula = SHAPE_PATH_FORMULAS[data.pathFormula];
      if ('editable' in pathFormula) {
        newElement.path = pathFormula.formula(
          width,
          height,
          pathFormula.defaultValue
        );
        newElement.keypoint = pathFormula.defaultValue;
      } else newElement.path = pathFormula.formula(width, height);
    }
    createElement(newElement);
  };

  /**
   * 创建线条元素
   * @param position 位置大小信息
   * @param data 线条的路径和样式
   */
  const createLineElement = (
    position: LineElementPosition,
    data: LinePoolItem
  ) => {
    const { left, top, start, end, } = position;

    const newElement: PPTLineElement = {
      type: 'line',
      id: createRandomCode(),
      left,
      top,
      start,
      end,
      path: data?.customPath || data?.path,
      points: data.points,
      color: themeColor.value,
      style: data.style,
      width: 2,
      customPath: data?.customPath,
      custom: _.cloneDeep(data?.custom),
    };
    if (data.isBroken) {
      newElement.broken = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];
    }

    if (data.isCurve) {
      newElement.curve = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];
    }
    createElement(newElement);
  };

  /**
   * 创建超链接按钮元素
   * @param link 链接内容
   */
  const createLinkButtonElement = (link: PPTElementLink, title?: string) => {
    const width = 400;
    const height = 57.5;
    createElement({
      type: 'link-button',
      id: createRandomCode(),
      width,
      height,
      title,
      left: (VIEWPORT_SIZE - width) / 2,
      top: (VIEWPORT_SIZE * viewportRatio.value - height) / 2,
      link,
    });
  };

  return {
    createImageElement,
    createImageElements,
    createChartElement,
    createTableElement,
    createTextElement,
    createShapeElement,
    createLineElement,
    createElfElement,
    createLinkButtonElement,
  };
};
