// eslint-disable-next-line
// @ts-ignore
import { eventCenter, event } from '@evideo/frontend-utils'
import { MutationTypes, store } from '@/store'
import logger from '@evideo/logger'
import useQianKunEventCenter from './useQianKunEventCenter'

interface LifeCycleCall {
	bootstrap?: () => void
	mount: (props: any) => void
	unmount?: () => void
}
const { name: appName, version: appVersion } = require('../../package.json')
let lifeCycleCall: LifeCycleCall | null = null
let inited = false
let document: Document | HTMLElement = window.document
export default (_lifeCycleCall?: LifeCycleCall) => {
	_lifeCycleCall && (lifeCycleCall = _lifeCycleCall as LifeCycleCall)
	const isMicroApp = !!window.__POWERED_BY_QIANKUN__
	// 初始化
	const init = () => {
		// 设置qiankun的public path
		isMicroApp &&
			(__webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__)
		inited = true
	}
	!inited && init()
	const setDocument = (_doc: Document | HTMLElement) => {
		document = _doc
		isMicroApp && (document.body = _doc.querySelector('#ppt') as HTMLElement)
	}

	return {
		appName,
		isMicroApp,
		eventCenter,
		event,
		document,
		setDocument,
		publicPath: window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__
	}
}

// 重写 乾坤微应用容器的样式, 预加载模式会使宽高发生变化
const setContainerStyle = (container: HTMLDivElement) => {
	const containerStyle = window.document.createElement('style')
	containerStyle.innerHTML = `
  div[id="${container.id}"] {
    height: 100%;
    width: 100%;
  }
  `
	container.appendChild(containerStyle)
}

// 预加载下级应用 并且定时判断 通知上级应用做啥事
const preloadMicoApp = ({
	appName,
	preload = false
}: {
	appName: string
	preload: boolean
}) => {
	// preload && await microapps.preload()

	let timeId: number | undefined = undefined
	let timeCount = 0

	timeId = window.setInterval(() => {
		if (timeCount > 50) {
			// 循环50次
			clearInterval(timeId)
			logger.warn('ppt,启动超时')
		}
		if (
			!preload &&
			eventCenter.send &&
			eventCenter?.argus &&
			eventCenter?.argus[event.PPTIST_LOAD_PPT]
		) {
			// 通知上级应用 可以加载第一份ppt
			eventCenter.send(`${appName}-load-ok`)
			clearInterval(timeId)
		} else if (preload && eventCenter.send) {
			// 通知上级应用 预加载完成，可以卸载应用了
			eventCenter.send(`${appName}-preloadOk`, {
				appName
			})
			clearInterval(timeId)
		} else {
			timeCount++
		}
	}, 100)
}

export const bootstrap = async () => {
	logger.info(`【${appName}-${appVersion}】 bootstrap`)
	lifeCycleCall?.bootstrap && (await lifeCycleCall?.bootstrap())
}
export const mount = async (props: any) => {
	logger.info(`【${appName}-${appVersion}】 mount`)
	logger.info(`【${appName}-${appVersion}】 props from main framework`, props)

	const { initStudentsModal } = useQianKunEventCenter()

	eventCenter.registerSend(props.setGlobalState)

	if (props?.args?.os) {
		logger.info(`【${appName}-${appVersion}】 当前环境为`, props.args.os)
		;(window as any).os = props.args.os
	}
	props.onGlobalStateChange((state: any) => {
		eventCenter.registerPubTrigger(state)
	})

	if (!eventCenter.__overwrite__) {
		// 重写订阅
		const eventCenter_sub = eventCenter.sub
		eventCenter.sub = function(...args: any[]) {
			logger.info(`【${appName}-${appVersion}】(sub): key:[${args[0]}]`)
			eventCenter_sub.apply(eventCenter, args)
		}

		// 重写发送
		const eventCenter_send = eventCenter.send
		eventCenter.send = function(...args: any[]) {
			logger.info(
				`【${appName}-${appVersion}】(send):key:[${args[0]}], params:`,
				args[1] || {}
			)
			eventCenter_send.apply(eventCenter, args)
		}
		eventCenter.__overwrite__ = true
	}
	const { container }: { container: HTMLDivElement } = props
	setContainerStyle(container)

	preloadMicoApp(props.args)

	!props.args?.preload && initStudentsModal()

	lifeCycleCall?.mount && (await lifeCycleCall?.mount(props))
	// 设置token
	if (props.args && props.args.token) {
		store.commit(MutationTypes.SET_TOKEN, props.args.token)
	}
	/**
	 * fixme: 动态加载loading样式，保证在微应用框下，能用，后续需要重构
	 */
	fetch(
		`${window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__}css/global-loading.css?v=1.1`
	)
		.then((res: any) => {
			return res.text()
		})
		.then((text: string) => {
			const loadingStyle = window.document.createElement('style') as any
			container.insertBefore(loadingStyle, container.children[0])
			if (loadingStyle.styleSheet) {
				loadingStyle.styleSheet.cssText = text
			} else {
				loadingStyle.appendChild(window.document.createTextNode(text))
			}
		})
}

export const preUnmount = () => {
	logger.info(`【${appName}-${appVersion} pre unmount`)
	// await microapps.destoryMicoAppEle()
}
export const unmount = async () => {
	const { closeStudentsModal } = useQianKunEventCenter()
	logger.info(`【${appName}-${appVersion}】 unmount`)
	// await destoryMicoAppEle()
	closeStudentsModal()

	logger.info(`【${appName}-${appVersion}】 unmount 关闭学生弹窗`)
	lifeCycleCall?.unmount && (await lifeCycleCall?.unmount())
	logger.info(`【${appName}-${appVersion}】 unmount 卸载音色库`)
	if (!window.SynthManager) {
		return
	}
	window.SynthManager.context?.close().then()
	if (window.SynthManager.sfontId !== undefined) {
		window.SynthManager.synth?.unloadSFont(window.SynthManager.sfontId)
	}
	window.SynthManager.synth?.close()
	window.SynthManager.synthReady = false
	window.SynthManager.paused = false
	window.SynthManager.seekTime = 0
}
