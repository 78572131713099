window.onmessage = (event: any) => {
	const { type, data } = event.data
	const customEvent = new CustomEvent(type, {
		detail: data
	})
	window.dispatchEvent(customEvent)
}
const postMessageEventsMap = new Map()

export default () => {
	const createKeydownHandler = (callback: (...args: any) => void) => (event: {
		detail: any
	}) => {
		callback(event, event.detail)
	}

	const addEventListener = (
		eventName: string,
		handler: (...args: any[]) => void
	) => {
		const _handler = createKeydownHandler(handler)
		postMessageEventsMap.set(handler, _handler)
		window.addEventListener(eventName, _handler as any)
	}

	const removeEventListener = (
		eventName: string,
		handler: (...args: any[]) => void
	) => {
		const _handler = postMessageEventsMap.get(handler)
		if (!_handler) return
		window.removeEventListener(eventName, _handler)
		postMessageEventsMap.delete(handler)
	}

	const removeAllEventListener = (
		eventName: string,
		handler?: (...args: any[]) => void
	) => {
		if (eventName && handler) {
			removeEventListener(eventName, handler)
		}
	}

	const sendToHost = (eventName: string, data: any) => {
		window.parent.postMessage(
			{ type: eventName, origin: window.location.href, data },
			'*'
		)
	}

	return {
		addEventListener,
		removeEventListener,
		sendToHost,
		removeAllEventListener
	}
}
