import { ref, toRaw } from 'vue'
import ModifyRecordsDatabase, {
	ModifyRecord
} from '@/utils/modifyRecordsDatabase'
import { useStore } from '@/store'
import { MD5 } from 'crypto-js'
import { PPTElement, Slide, SlideTheme } from '@/types/slides'
import _ from 'lodash'
import dayjs from 'dayjs'
import logger from '@evideo/logger'

interface ModifyRecordsOptions {
	intervalTime: number
	maxRecordsLen: number
}
let modifyRecordsDB: ModifyRecordsDatabase | undefined = undefined
const compareModifyDiff = ref(false) // 初次对比云端与最新记录是否一致

export default () => {
	const options = ref<ModifyRecordsOptions>({
		intervalTime: 60000,
		maxRecordsLen: 20
	})
	let intervalId: any = undefined

	const store = useStore()

	const initModifyRecords = () => {
		endIntervalSaveModify()
		logger.info(`modify records init uuid: ${store.state.uuid}`)
		modifyRecordsDB = new ModifyRecordsDatabase(store.state.uuid)
		modifyRecordsDB.clearByTime(
			store.state.uuid,
			dayjs()
				.subtract(1, 'month')
				.format('YYYY-MM-DD HH:mm:ss')
		)
		compareNewRecordAndCloud().then((res) => {
			compareModifyDiff.value = res
		})
	}

	const compareNewRecordAndCloud = async () => {
		if (!modifyRecordsDB) {
			return false
		}
		const list = await modifyRecordsDB.modifyRecords
			.orderBy('id')
			.filter((modifyRecord) => modifyRecord.uuid === store.state.uuid)
			?.toArray()
		const lastModifyRecord = list?.pop()
		if (lastModifyRecord) {
			return !checkIsDifferentByMd5(lastModifyRecord)
		}
		return false
	}

	const startIntervalSaveModify = () => {
		logger.info(`modify records: start interval save modify`)
		intervalId = setInterval(() => {
			addModify()
		}, options.value.intervalTime)
	}
	const addModify = async () => {
		if (!modifyRecordsDB) {
			return
		}
		compareModifyDiff.value = false
		const list = await modifyRecordsDB.modifyRecords
			.orderBy('id')
			.filter((modifyRecord) => modifyRecord.uuid === store.state.uuid)
			?.toArray()
		const lastModifyRecord = list?.pop()
		if (!list || (list && list.length === 0) || !lastModifyRecord) {
			await addModifyRecord()
			return
		}
		const isDiff = !checkIsDifferentByMd5(lastModifyRecord)
		logger.info(`modify records: check md5 is different : ${isDiff}`)
		if (isDiff) {
			await addModifyRecord()
		}
	}
	const endIntervalSaveModify = () => {
		logger.info(
			`modify records: end interval save modify , intervalId: ${intervalId}`
		)
		intervalId && clearInterval(intervalId)
	}
	const checkIsDifferentByMd5 = (lastModifyRecord: ModifyRecord) => {
		const lastModifyRecordStr = filterJsonToString({
			slides: lastModifyRecord.slides,
			theme: lastModifyRecord.theme
		})
		const currentJsonStr = filterJsonToString({
			slides: toRaw(store.state.slides),
			theme: toRaw(store.state.theme)
		})
		if (
			MD5(lastModifyRecordStr).toString() !== MD5(currentJsonStr).toString()
		) {
			return false
		}
		return true
	}
	const filterJsonToString = (jsonData: {
		slides: Slide[]
		theme: SlideTheme
	}) => {
		const _json = _.cloneDeep(jsonData)
		const filterSlideKeys = ['preview', 'previewSize', 'previewMd5']
		const filterElementKeys = ['_meta']
		_json.slides.map((slide: Slide) => {
			Object.keys(slide).forEach((_key: string) => {
				if (filterSlideKeys.includes(_key)) {
					delete slide[_key]
				}
				slide.elements.map((el: PPTElement) => {
					Object.keys(el).forEach((_elKey: string) => {
						if (filterElementKeys.includes(_elKey)) {
							delete el[_elKey]
						}
					})
				})
			})
			return slide
		})
		return JSON.stringify(_json)
	}
	const addModifyRecord = async () => {
		if (!modifyRecordsDB) {
			return
		}
		const allKeys = await modifyRecordsDB.modifyRecords
			.orderBy('id')
			.filter((modifyRecord) => modifyRecord.uuid === store.state.uuid)
			.keys()
		const newModifyRecord: ModifyRecord = {
			uuid: store.state.uuid,
			time: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
			slides: _.cloneDeep(store.state.slides),
			theme: _.cloneDeep(store.state.theme)
		}
		await modifyRecordsDB.modifyRecords.add(newModifyRecord)
		if (allKeys.length + 1 > options.value.maxRecordsLen) {
			await modifyRecordsDB.modifyRecords.delete(allKeys[0] as number)
		}
	}
	const getModifyRecordsList = async () => {
		try {
			if (!modifyRecordsDB) {
				modifyRecordsDB = new ModifyRecordsDatabase(store.state.uuid)
			}
			const list = await modifyRecordsDB.modifyRecords
				.orderBy('id')
				.filter((modifyRecord) => modifyRecord.uuid === store.state.uuid)
				?.toArray()
			return list
		} catch (err) {
			logger.error('获取修改记录失败：', err)
		}
	}

	return {
		compareModifyDiff,
		initModifyRecords,
		startIntervalSaveModify,
		endIntervalSaveModify,
		getModifyRecordsList,
		addModify
	}
}
