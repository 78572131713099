/*
 * @description: 页面加载效果,需要在根组件，比如app.vue中加Spin,并使用loading作为modal绑定
  <Spin tip="加载中..." size="large" wrapperClassName="ppt-loading" :spinning="loading">
  **
  </Spin>

  需要加样式，才能保证包裹在内部的宽高正确显示
  .ppt-loading {
    width: 100%;
    height: 100%;
    .ant-spin-container {
      width: 100%;
      height: 100%;
    }
    div {
      .ant-spin {
        max-height: 100%;
      }
    }
  }
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-08-25 11:13:46
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-08-25 13:42:36
 */
import _ from 'lodash'
import { message } from 'ant-design-vue'
import useQianKun from './useQianKun'
import useQianKunEventCenter from './useQianKunEventCenter'
import logger from '@evideo/logger'

const showMeassage = _.throttle(
	(
		_message: string,
		type: 'info' | 'success' | 'warning' | 'error' = 'info'
	) => {
		const { isMicroApp, document } = useQianKun()
		const { notifyMessage } = useQianKunEventCenter()
		if (isMicroApp) {
			notifyMessage({
				message: _message,
				type,
				element: document.body
			}).then((res: any) => {
				logger.info('start full screen:', res)
			})
		} else {
			message[type](_message, 1)
		}
	},
	1000,
	{ leading: true, trailing: false }
)
export default () => {
	return {
		showMeassage
	}
}
