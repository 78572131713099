import { reactive } from 'vue'
import runtime, { Runtime } from '@/configs/runtime'
import { store } from '@/store'
import _ from 'lodash'

const proxyRunTime: Runtime = reactive(runtime)

export default () => {
	const isDevelopment = process.env.NODE_ENV === 'development'
	const setUploadMode = (uploadMode: 'local' | 'cloud') => {
		proxyRunTime.uploadMode = uploadMode
	}

	const configRunTime = (runtime?: Runtime) => {
		runtime && _.merge(proxyRunTime, runtime || {})

		// 如果有传状态值，就更改状态值
		proxyRunTime.state &&
			Object.entries(proxyRunTime.state).forEach(([key, value]) => {
				store.state[key] = value
			})
		if (
			proxyRunTime.state &&
			runtime &&
			(runtime?.editPptRule?.mode === 'onlyPpt' ||
				runtime?.editPptRule?.mode === 'multipleTabs')
		) {
			store.state.editingScreening = true
		}
	}

	return {
		runtime: proxyRunTime,
		isDevelopment,
		setUploadMode,
		configRunTime
	}
}
